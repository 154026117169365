/* Variables */
$primary-color: #0e0e0e;
$secondary-color: #333333;
$background-color: #f4f4f4;
$button-color: #4682B4;

/* Dark Mode Variables */
$primary-color-dark: #2C2C2E;
$secondary-color-dark: #3A3A3C;
$background-color-dark: #1C1C1E;
$button-color-dark: #4682B4;
$text-color-dark: #F0F0F0;

/* Light Mode Variables */
$text-color-light: #0e0e0e;

.container {
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
  color: #343a40;

  &.darkMode {
    background-color: $background-color-dark;
    color: $text-color-dark;
  }

  &.lightMode {
    background-color: $background-color;
    color: $text-color-light;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.innerContainer {
  max-width: 900px;
  margin: 0 auto;
}

.headerContainer {
  text-align: center;
  margin-bottom: 30px;
}

.header {
  font-size: 2rem;
  color: #343a40;
}

.overallSummary {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.overallItem {
  background-color: #A3E3BA;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 10px #58D18495;
  text-align: center;
  flex: 1 1 calc(25% - 20px);
}

.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.tabButton {
  background-color: #e9ecef;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #495057;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(#e9ecef, 10%);
  }

  &.active {
    background-color: #4682B4;
    color: #ffffff;
  }

  &.disabled {
    background-color: lighten(#e9ecef, 10%);
    cursor: not-allowed;
  }
}

.scrollableContent {
  max-height: 60vh;
  overflow-y: scroll;
}

.summaryBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 20px;
}

.subHeader {
  width: 100%;
  margin-bottom: 20px;
  color: #343a40;
  text-align: center;
}

.summaryItem {
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 10px;
  width: 45%;
  max-width: 200px;
}
.tableContainer {
  overflow-x: auto;
  width: 100%;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin: 20px auto;
  overflow: hidden;
  max-width: 900px;
  align-items: center;
}

.tableHeaderRow {
  background-color: #4682B4;
  color: #ffffff;
}

.tableHeader {
  padding: 12px;
  text-align: left;
}

.tableRowEven {
  background-color: #f8f9fa;
}

.tableRowOdd {
  background-color: #ffffff;
}

.tableCell {
  padding: 12px;
  border-top: 1px solid #dee2e6;
}

.tableCell:first-child,
.tableHeader:first-child {
  border-left: none;
}

.tableCell:last-child,
.tableHeader:last-child {
  border-right: none;
}

/* Dark mode styles */
.darkMode .container {
  background-color: #343a40;
  color: #f8f9fa;
}

.darkMode .header {
  color: #f8f9fa;
}

.darkMode .overallItem {
  background-color: #495057;
  box-shadow: 0px 5px 10px #343a40;
}

.darkMode .tabButton {
  background-color: #6c757d;
  color: #f8f9fa;

  &.active {
    background-color: #4682B4;
    color: #ffffff;
  }

  &.disabled {
    background-color: lighten(#6c757d, 10%);
    cursor: not-allowed;
  }
}

.darkMode .summaryBox {
  background-color: #495057;
  border: 1px solid #6c757d;
}

.darkMode .summaryItem {
  background-color: #6c757d;
  color: #ffffff;
}

.darkMode .table {
  background-color: #495057;
  border: 1px solid #6c757d;
}

.darkMode .tableHeaderRow {
  background-color: #4682B4;
}

.darkMode .tableRowEven {
  background-color: #343a40;
}

.darkMode .tableRowOdd {
  background-color: #495057;
}

.darkMode .tableCell {
  border-top: 1px solid #6c757d;
}

/* Dark mode styles */
.darkMode .container {
  background-color: #343a40;
  color: #f8f9fa;
}

.darkMode .header {
  color: #f8f9fa;
}

.darkMode .overallItem {
  background-color: #495057;
  box-shadow: 0px 5px 10px #343a40;
}

.darkMode .tabButton {
  background-color: #6c757d;
  color: #f8f9fa;

  &.active {
    background-color: #4682B4;
    color: #ffffff;
  }

  &.disabled {
    background-color: lighten(#6c757d, 10%);
    cursor: not-allowed;
  }
}

.darkMode .summaryBox {
  background-color: #495057;
  border: 1px solid #6c757d;
}

.darkMode .summaryItem {
  background-color: #6c757d;
  color: #ffffff;
}

.darkMode .table {
  background-color: #495057;
  border: 1px solid #6c757d;
}

.darkMode .tableHeaderRow {
  background-color: #4682B4;
}

.darkMode .tableRowEven {
  background-color: #343a40;
}

.darkMode .tableRowOdd {
  background-color: #495057;
}

.darkMode .tableCell {
  border-top: 1px solid #6c757d;
}
