/* Base styles that apply to both light and dark modes */
.app {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    position: relative;
    overflow: hidden;
  }
  
  .header h1 {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 700;
    margin: 1rem 0;
  }
  
  .header p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  
  .features {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  
  .feature {
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 20px;
    transition: all 0.3s ease;
    width: 280px;
    margin: 10px;
    transform: scale(1);
  }
  
  .feature:hover {
    transform: scale(1.05);
  }
  
  .featureLink {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .icon {
    font-size: 48px;
    transition: transform 0.2s ease-in-out;
  }
  
  .feature:hover .icon {
    transform: rotate(10deg);
  }
  
  @media (max-width: 768px) {
    .features {
      flex-direction: column;
    }
  
    .feature {
      width: 90%;
    }
  }
  
  /* Dark mode styles */
  .app-dark {
    background: linear-gradient(145deg, #0F172A 0%, #1E293B 100%);
    color: #E2E8F0;
  }
  
  .app-dark .header h1 {
    color: #FFFFFF;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .app-dark .header p {
    color: #CBD5E1;
  }
  
  .app-dark .feature {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 4px 6px rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .app-dark .feature:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), inset 0 8px 12px rgba(255, 255, 255, 0.2);
  }
  
  .app-dark .featureLink {
    color: #E2E8F0;
  }
  
  .app-dark .icon {
    color: #22D3EE;
  }
  
  /* Light mode styles (you can adjust the colors as you like) */
  .app-light {
    background: #FFFFFF; /* Light background */
    color: #000000; /* Dark text for readability */
  }
  
  .app-light .header h1 {
    color: #000000;
  }
  
  .app-light .header p {
    color: #4A5568;
  }
  
  .app-light .feature {
    background: #F7FAFC; /* Lighter background for features */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), inset 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .app-light .feature:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .app-light .featureLink {
    color: #2D3748;
  }
  
  .app-light .icon {
    color: #4A5568; /* Darker icon color for contrast */
  }
  /* Defining color schemes for light and dark modes */
:root {
  --color-primary: #007bff;
  --color-primary-dark: #0056b3;
  --color-background-light: #ffffff;
  --color-background-dark: #121212;
  --color-text-light: #333333;
  --color-text-dark: #e0e0e0;
  --color-error-light: #dc3545;
  --color-error-dark: #f5c2c7;
  --shadow: 0 2px 4px rgba(0,0,0,0.1);
  --border-radius: 8px;
}

[data-theme='dark'] {
  --color-background: var(--color-background-dark);
  --color-text: var(--color-text-dark);
  --color-error: var(--color-error-dark);
  --input-background: #333;
}
/* Define default (light theme) variables */
:root {
  --background-color: #fff;
  --text-color: #000;
  --button-bg-color: #e0e0e0;
  --button-text-color: #000;
}

/* Define dark theme variables */
[data-theme='dark'] {
  --background-color: #333;
  --text-color: #fff;
  --button-bg-color: #555;
  --button-text-color: #fff;
}

:root {
  --color-background: var(--color-background-light);
  --color-text: var(--color-text-light);
  --color-error: var(--color-error-light);
  --input-background: #fff;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  transition: background-color 0.3s, color 0.3s;
}

.container {
  width: 69%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--color-background);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
}

/* Responsive typography */
h1 {
  font-size: 1.75rem;
  text-align: center;
  color: var(--color-primary);
}

.form {
  display: grid;
  grid-gap: 20px;
  margin-top: 2rem;
}

.input, .select {
  padding: 0.75rem;
  border: 2px solid var(--color-primary);
  border-radius: var(--border-radius);
  background-color: var(--input-background);
  color: var(--color-text);
  transition: border-color 0.3s;
}

.input:focus, .select:focus {
  outline: none;
  border-color: var(--color-primary-dark);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.button {
  padding: 0.75rem;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: var(--color-primary-dark);
}

.error {
  padding: 1rem;
  background-color: var(--color-error);
  color: white;
  border-radius: var(--border-radius);
  text-align: center;
}

/* Utility classes for layout */
.spacer {
  margin-top: 2rem;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsiveness */
@media (max-width: 768px) {
  .container {
    width: auto; /* Allow the container to fit the content width */
    margin: 1rem; /* Smaller margin on the sides */
    padding: 1rem; /* Comfortable padding */
  }

  h1 {
      font-size: 1.5rem;
  }
}

/* Loading Spinner Styles */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--color-primary);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  /* Center the spinner */
  margin: 30px auto;
  display: block;
}
/* Loading container to center spinner and text */
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

/* Styling for the loading text */
.loadingText {
  margin-top: 10px;
  color: var(--color-primary); /* Use your theme's primary color */
  font-size: 1rem;
  text-align: center;
}
/* Container for the entire component */
.analogyContainer {
  background-color: var(--color-bg);
  padding: 1rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* Table Wrapper */
.tableWrapper {
  margin-bottom: 2rem;
  overflow-x: auto;
}

/* Table styles */
.analogyTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden; /* Ensures the border radius clips the content */
}

.analogyTable thead th {
  background-color: var(--color-primary);
  color: white;
  padding: 0.75rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.analogyTable tbody td {
  padding: 0.75rem;
  color: var(--color-text);
  border-bottom: 1px solid var(--color-border);
}

.analogyTable tbody tr:last-child td {
  border-bottom: 0;
}

/* Hover effect for table rows */
.analogyTable tbody tr:hover {
  background-color: var(--color-primary);
  color: white;
}

/* Additional Information Styling */
.additionalInfoContainer {
  padding: 1rem;
  margin-top: 1rem;
  background-color: #f9f9f9;
  border-left: 4px solid var(--color-primary);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.additionalInfo {
  margin-bottom: 1rem;
  padding: 0.5rem 0;
}

.additionalInfo strong {
  display: block; /* Makes the label block so the value goes under the label */
  color: var(--color-primary);
  margin-bottom: 0.25rem;
}

.additionalInfo pre {
  background-color: #e9ecef;
  border-radius: var(--border-radius);
  padding: 1rem;
  margin-top: 0.5rem;
  overflow-x: auto;
  white-space: pre-wrap; /* Ensures pre-formatted text wraps */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .additionalInfoContainer {
    padding: 0.5rem;
  }

  .additionalInfo pre {
    padding: 0.5rem;
  }
}

/* Responsive Typography */
@media (max-width: 768px) {
  :root {
    --font-size-base: 0.9rem;
  }
  .analogyTable thead th, .additionalInfo strong {
    font-size: calc(var(--font-size-base) - 0.1rem); /* Slightly smaller font size for table headers and strong labels */
  }

  /* Adjust modal for better mobile viewing */
  .modal {
    width: 95%; /* Take up almost full width on smaller screens */
    max-height: 90vh; /* Smaller height to ensure the modal fits within the viewport */
    overflow-y: auto; /* Add scroll to the modal itself if content is too tall */
  }
  
  /* Ensure the body doesn't get squished by making the modal header and footer more compact */
  .modalHeader {
    padding: 0.5rem;
  }

  .modalBody {
    padding: 1rem; /* Comfortable padding but not too large */
  }
  .analogyTable thead th {
    font-size: var(--font-size-base);
  }
}

.infoButton {
  padding: 0.5rem 1rem;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.infoButton:hover {
  background-color: var(--color-primary-dark);
}
.tweetContainer {
  border: 1px solid #eff3f4;
  border-radius: 16px;
  padding: 10px 12px;
  margin: 10px 0;
  background-color: #fff;
  display: flex;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.profileImg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}

.tweetHeader {
  display: flex;
}

.tweetAuthorDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authorName {
  font-weight: bold;
  font-size: 15px;
}

.authorHandle {
  color: #657786;
  font-size: 15px;
}

.tweetBody p {
  margin: 5px 0;
}

.tweetInteractions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 5px;
}

.tweetInteractions svg {
  color: #657786;
  cursor: pointer;
}

/* Add other styles as necessary */
.toggleButton {
  background-color: #1da1f2; /* Twitter Blue */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.toggleButton:hover,
.toggleButton:focus {
  background-color: #1a91da; /* A slightly darker blue */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.toggleButton:active {
  background-color: #1781bf; /* Even darker blue */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* You can add this to disable the button visually when it's not clickable */
.toggleButton:disabled {
  background-color: #a4d9f7;
  color: #f0f0f0;
  cursor: default;
  box-shadow: none;
}
/* Root variable definitions for enhanced visual appeal */
:root {
  /* Light theme colors */
  --container-bg-color-light: #F7FAFC; /* Lighter shade for the background, enhancing readability */
  --text-color-light: #2D3748; /* Darker text for better contrast */
  --button-bg-color-light: #3182CE; /* A vibrant color for buttons */
  --button-text-color-light: #FFFFFF; /* Ensuring text on buttons is readable */
  --input-border-color-light: #CBD5E1; /* Softer border color for inputs */
  --icon-color-light: #4A90E2; /* A distinct color for icons */

  /* Dark theme colors */
  --container-bg-color-dark: #2D3748; /* Dark theme container with more visual depth */
  --text-color-dark: #E2E8F0; /* Lighter text to contrast the dark background */
  --button-bg-color-dark: #63B3ED; /* Lighter button color for dark mode */
  --button-text-color-dark: #2D3748; /* Dark text for buttons in dark mode */
  --input-border-color-dark: #4A5568; /* A contrasted border color for inputs in dark mode */
  --icon-color-dark: #90CDF4; /* A brighter icon color for visibility in dark mode */
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--container-bg-color-light); /* Defaulting to light theme */
}

.app-light {
  --container-bg-color: var(--container-bg-color-light);
  --text-color: var(--text-color-light);
  --button-bg-color: var(--button-bg-color-light);
  --button-text-color: var(--button-text-color-light);
  --input-border-color: var(--input-border-color-light);
  --icon-color: var(--icon-color-light);
}

.app-dark {
  --container-bg-color: var(--container-bg-color-dark);
  --text-color: var(--text-color-dark);
  --button-bg-color: var(--button-bg-color-dark);
  --button-text-color: var(--button-text-color-dark);
  --input-border-color: var(--input-border-color-dark);
  --icon-color: var(--icon-color-dark);
}

.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--container-bg-color);
  color: var(--text-color);
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.input {
  width: 100%;
  padding: 14px;
  border: 1px solid var(--input-border-color);
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: #fff;
}

.input:focus {
  outline: none;
  border-color: #4A90E2; /* Highlight color for input focus */
}

.button {
  padding: 12px 24px;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #555; /* Darken button on hover for visual feedback */
}

.tweetContainer {
  padding: 20px;
  margin: 20px 0;
  background-color: var(--container-bg-color);
  border-radius: 12px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 -5px 10px rgba(0, 0, 0, 0.05); /* Enhanced shadow for depth */
}

.tweetHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profileImg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}

.authorName {
  font-weight: bold;
  font-size: 16px;
  color: var(--text-color);
}

.authorHandle {
  color: #657786; /* Subtle color for the handle */
  font-size: 14px;
}

.tweetInteractions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.tweetInteractions svg {
  color: var(--icon-color);
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .tweetContainer {
    margin: 10px 0;
  }
}

/* Dropdown (select) container styles for positioning and alignment */
.toggleContainer {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
/* Enhanced Dropdown (select) styling for better visibility */
select {
  padding: 12px 16px;
  background-color: var(--button-bg-color); /* Contrasting background */
  color: var(--button-text-color); /* Text color for contrast */
  border: 2px solid var(--button-text-color); /* Contrasting border */
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  appearance: none; /* Removes default styling */
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23000000" d="M7 10l5 5 5-5z"/></svg>'); /* Custom dropdown arrow, now in black */
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

select:hover {
  background-color: lighten(var(--button-bg-color), 10%); /* Lighten on hover */
  border-color: lighten(var(--button-text-color), 10%);
}

select:focus {
  border-color: darken(var(--button-bg-color), 10%); /* Darken border on focus */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on focus */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .toggleContainer {
    width: 90%;
  }

  select {
    width: 100%; /* Ensures dropdown fits container width on smaller screens */
  }
}

  
.textarea {
  width: 100%;
  padding: 14px;
  border: 1px solid var(--input-border-color);
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: #fff;
  resize: none; /* Prevents manual resizing */
  overflow-y: auto; /* Ensures scrollbar appears when content exceeds height */
  min-height: 100px; /* Minimum height before typing */
  max-height: 300px; /* Maximum height before becoming scrollable */
  border: 2px solid #DDDDDD; /* A light grey border, common in designs like Airbnb's */

}

.textarea:focus {
  outline: none;
  border-color: #4A90E2; /* Highlight color for focus */
}

/* Modal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
}

.modal {
  background: white;
  width: 40%; /* Reduced width */
  max-height: 70vh; /* Maximum height */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Ensure the header and body are stacked */
  overflow: hidden; /* Keep this to prevent the shadow from leaking */
}

.modalBody {
  padding: 20px;
  overflow-y: auto; /* Add scroll to the modal body */
  flex-grow: 1; /* Ensure it takes up the necessary space */
}

.modalHeader {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; /* Light grey background */
  border-bottom: 1px solid #eee; /* Light grey border */
}

.modalTitle {
  margin: 0;
  font-size: 18px;
}

.closeButton {
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}


/* Adjustments for responsiveness */
@media (max-width: 768px) {
  .modal {
    width: 80%; /* More screen width on smaller devices */
  }
}
