.green {
  background-color: #8EE7AE !important;
}

.box.green {
  background-color: #aeabab !important;
}

.grid {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr) !important;
  grid-gap: 0px !important;
  width: 100% !important;
  max-width: 800px !important;
  max-height: 800px !important;
  height: auto !important;
  margin: 0 auto !important;
  margin-top: 10px !important;
  grid-column-gap: 1px !important;
  grid-row-gap: 1px !important;
  padding-right: 160px !important;
  position: relative !important;
}

.pgalogo {
  position: absolute !important;
  top: 75% !important; // Changed from 50% to 75% to move it lower
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 40% !important;
  max-width: 250px !important;
  height: auto !important;
  opacity: 0.15 !important;
  pointer-events: none !important;
  z-index: 1 !important;
}

.statsGrid {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr) !important;
  grid-gap: 0px !important;
  width: 100% !important;
  max-width: 600px !important;
  max-height: 600px !important;
  height: auto !important;
  margin: 0 auto !important;
  margin-top: 10px !important;
  grid-column-gap: 1px !important;
  grid-row-gap: 1px !important;
  padding-right: 110px !important;
}

.twitter {
  text-decoration: none !important;
  align-items: center !important;
  justify-content: center !important;
}

@media (max-width: 800px) {
  .grid {
    padding-right: calc((90vw - 20px) / 4) !important;
  }

  .statsGrid {
    padding-right: calc((90vw - 20px) / 4) !important;
  }
}

