$primary-color: #003A70;
$secondary-color: #E8000B;
$background-color: #f5f5f5;
$background-color-dark: #f5f5f5;
$white-color: #ffffff;
$text-color: #202124;
$text-color-dark: #202124;
$subtitle-color: #5f6368;
$subtitle-color-dark: #8d8d8d;
$border-color: #dadce0;
$border-color-dark: #dadce0;
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-dark: rgba(0, 0, 0, 0.7);
$hover-transform: translateY(-2px);
$button-radius: 8px;
$container-radius: 16px;
$button-background-color: #f2f2f2;
$button-background-color-dark: #f2f2f2;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: $background-color;
  overflow-x: hidden;
  color: $text-color;

  &.dark-mode {
    background-color: $background-color;
    color: $text-color-dark;
  }
}

.container {
  padding: 2rem 1rem;
  background-color: $white-color;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 4px 20px $shadow-color;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 1rem;
  }

  &.dark-mode {
    background-color: $background-color-dark;
    box-shadow: 0 4px 20px $shadow-color-dark;
  }
}
.headerContainer {
  background-color: $white-color;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center; // Center everything horizontally
  border-bottom: 1px solid $border-color;
  position: relative; // Allows absolute positioning of the toggle switch

  .logo {
    max-height: 80px;

    @media (max-width: 600px) {
      max-height: 60px; // Smaller logo on mobile
      margin-bottom: 0.25rem; // Adds some space between logo and button
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center; // Center align the buttons below the logo
    margin-top: 0.5rem;

    @media (max-width: 600px) {
      align-items: center;
    }
  }

  .helpButton {
    background-color: $primary-color;
    color: $white-color;
    border: none;
    border-radius: 12px; // Modern rounded corners
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    margin-top: 0.5rem; // Adds some space between logo and button
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for a floating effect

    &:hover {
      background-color: $secondary-color;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); // Slightly stronger shadow on hover
      transform: translateY(-2px); // Slight lift on hover
    }

    &:active {
      transform: translateY(1px); // Slight press effect
    }
  }

  .viewSwitchContainer {
    position: absolute; // Position it absolutely within the header
    top: 50%;
    right: 1rem; // Push it to the right side
    transform: translateY(-50%); // Vertically center it

    @media (max-width: 600px) {
      right: 0.5rem;
    }
  }
}



.logo {
  max-height: 80px;

  @media (max-width: 600px) {
    max-height: 60px; // Smaller logo on mobile
    margin-bottom: 0.25rem; // Adds some space between logo and button
  }
}



.viewSelectionsButton {
  background-color: $primary-color;
  color: $white-color;
  padding: 1rem 2rem;
  border-radius: $button-radius;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: $secondary-color;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}





.header {
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
  color: $primary-color;
  font-size: 2rem;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
}

.subtitle {
  text-align: center;
  margin: 1.5rem 0; // Increased margin to give more space
  color: $subtitle-color;
  font-size: 1rem;

  @media (max-width: 600px) {
    font-size: 0.875rem;
    margin: 1rem 0; // Smaller margins on mobile
  }
}


.playerSelection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
  overflow-x: hidden;

  @media (max-width: 600px) {
    gap: 0.5rem;
  }
}

.playerButton {
  background-color: $white-color;
  color: $text-color;
  padding: 0.5rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  cursor: pointer;
  text-align: center;
  position: relative;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s, border-color 0.3s, color 0.3s;
  font-size: 0.75rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: $background-color;
    transform: $hover-transform;
    box-shadow: 0 4px 8px $shadow-color;
    color: $text-color;
  }

  &.selected {
    border-color: $secondary-color;
    background-color: rgba(232, 0, 11, 0.1);
  }

  .viewProfileLink {
    display: block;
    text-decoration: none;
    font-size: 0.75rem;
    color: $primary-color;
    margin-top: 0.5rem;

    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.playerImage {
  width: 100%;
  height: auto;
  border-radius: $button-radius;
}

.playerName {
  display: block;
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 0.875rem;
}

.playerDetails {
  font-size: 0.75rem;
  color: $subtitle-color;
  word-break: break-word;

  .points,
  .position {
    display: block;
    margin: 0;
  }
}

.selectionNumber {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: $secondary-color;
  color: $white-color;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
}

.stickyFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white-color;
  box-shadow: 0 -4px 20px $shadow-color;
  padding: 1rem 2rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  border-radius: 16px 16px 0 0;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 0.5rem 1rem;
  }
}

.selectionCount {
  font-size: 1rem;
  font-weight: 500;
  color: $text-color;

  @media (max-width: 600px) {
    font-size: 0.875rem;
  }
}

.viewSelectionsButton,
.submitButton,
.nextButton,
.shareButton {
  background-color: $button-background-color;
  color: $primary-color;
  padding: 0.75rem 1.5rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s, color 0.3s;

  &:hover {
    background-color: $background-color;
    transform: $hover-transform;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    color: $primary-color;
  }

  @media (max-width: 600px) {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}.overlayContent {
  background: $white-color;
  padding: 2rem;
  border-radius: $container-radius;
  width: 90%;
  max-width: 800px;
  text-align: left;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  color: $text-color; // Ensures text color is consistent

  @media (max-width: 600px) {
    padding: 1rem;
    max-width: 95%;
    overflow-x: hidden;
  }
}

.overlayContent2 {
  background: $white-color;
  padding: 2rem;
  border-radius: $container-radius;
  width: 90%;
  max-width: 800px;
  text-align: left;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  color: $text-color; // Ensures text color is consistent

  @media (max-width: 600px) {
    padding: 1rem;
    max-width: 95%;
    overflow-x: hidden;
  }
}

.overlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: $text-color; // Ensures text color is consistent
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: $text-color; // Ensures text color is consistent
}

.picksList {
  list-style: none;
  padding: 0;
  max-height: 50vh;
  overflow-y: auto;
  color: $text-color; // Ensures text color is consistent

  @media (max-width: 600px) {
    max-height: 40vh;
    overflow-x: hidden;
  }
}

.pickItem {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }

  .pickControls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    font-size: 1.25rem;
  }

  .removeButton {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: red;
  }
}

.pickItemNumber {
  font-weight: bold;
  margin-right: 1rem;
  color: $text-color; // Ensures text color is consistent
}

.arrowButton {
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  color: $primary-color;

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
}

.submitContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid $border-color;
  width: 100%;
  color: $text-color; // Ensures text color is consistent

  .backButton {
    margin-bottom: 1rem;
  }
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
}

.input {
  padding: 0.75rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  font-size: 1rem;
  width: 100%;
  color: $text-color; // Ensures input text color remains consistent
}

.optIn {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  input {
    margin-right: 0.5rem;
  }

  label {
    font-size: 0.875rem;
    color: $text-color; // Ensures label text color remains consistent
  }

  a {
    margin-left: 0.5rem;
    color: $primary-color; // Ensures link text color remains consistent
  }
}

.notification {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: $secondary-color;
  color: $white-color;
  padding: 1rem 2rem;
  border-radius: $button-radius;
  z-index: 10000;
}

.footer {
  text-align: center;
  padding: 1rem 0;
  background-color: $white-color;
  border-top: 1px solid $border-color;

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modalContent {
  background: $white-color;
  padding: 2rem;
  border-radius: $container-radius;
  width: 90%;
  max-width: 600px;
  text-align: left;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
  color: $text-color; // Ensures modal content text color remains consistent

  @media (max-width: 600px) {
    padding: 1rem;
    max-width: 95%;
    overflow-x: hidden;
  }
}

.modalContent .helpCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  margin-bottom: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: $text-color; // Ensures close button text color is consistent
}

.modalBody {
  max-height: 70vh;
  overflow-y: auto;
  color: $text-color; // Ensures modal body text color is consistent

  @media (max-width: 600px) {
    max-height: 50vh;
    overflow-x: hidden;
  }
}

.prizeItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  i {
    font-size: 1.5rem;
  }
}

.officialRulesLink {
  display: block;
  margin-top: 1rem;
  color: $primary-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.sharePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.sharePopupContent {
  background: $white-color;
  padding: 2rem;
  border-radius: $container-radius;
  width: 90%;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  color: $text-color; // Ensures share popup content text color is consistent

  @media (max-width: 600px) {
    padding: 1rem;
    max-width: 95%;
    overflow-x: hidden;
  }
}

.shareText {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  font-size: 1rem;
  margin-bottom: 1rem;
  color: $text-color; // Ensures share text color remains consistent
}

.copyButton,
.tweetButton,
.shareButton,
.closeButton {
  background-color: $button-background-color;
  color: $primary-color;
  padding: 0.75rem 1.5rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s, color 0.3s;

  &:hover {
    background-color: $background-color;
    transform: $hover-transform;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    color: $primary-color;
  }
}
.floatingLabel {
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 0.5rem;
    border: 1px solid $border-color;
    border-radius: $button-radius;
    font-size: 1rem;
    outline: none;
    color: $text-color;
    background-color: $white-color;

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      top: -0.75rem;
      left: 0.5rem;
      font-size: 0.75rem;
      color: $primary-color;
      background-color: $white-color; // Ensure the label background matches the input background
      padding: 0 0.25rem; // Add some padding to create a clear background
      z-index: 1; // Ensure the label appears above the input border
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    color: $subtitle-color;
    background-color: transparent;
    padding: 0 0.25rem;
    pointer-events: none;

    // When input is focused or contains text, move the label
    input:focus ~ &,
    input:not(:placeholder-shown) ~ & {
      top: -0.75rem;
      left: 0.5rem;
      font-size: 0.75rem;
      color: $primary-color;
      background-color: $white-color;
      padding: 0 0.25rem;
      z-index: 1;
    }
  }
}

.wide {
  flex: 1;
}

.formBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.backButton {
  background-color: $button-background-color;
  color: $primary-color;
  padding: 0.75rem 1.5rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s, color 0.3s;

  &:hover {
    background-color: $background-color;
    transform: $hover-transform;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    color: $primary-color;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 600px) {
  .nextButton,
  .submitButton {
    width: 100%;
    margin-bottom: 1rem;
  }

  .tieBreaker {
    font-size: 0.75rem;
    color: $text-color; // Ensures text color remains consistent
  }
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  button {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    color: $text-color; // Ensures tab text color remains consistent

    &:hover,
    &.activeTab {
      color: $secondary-color;
      border-bottom: 2px solid $secondary-color;
    }
  }
}

.tabContent {
  padding: 1rem 0;
  text-align: left;
  color: $text-color; // Ensures tab content text color remains consistent

  h3 {
    margin-top: 0;
  }
}
.leaderboardTable {
  width: 80%;  // Reduce the table width to bring it in from the edges
  margin: 2rem auto;  // Center the table horizontally and add some vertical margin
  border-collapse: collapse;
  box-shadow: 0 4px 20px $shadow-color;
  border-radius: $container-radius;
  overflow: hidden;
}

.leaderboardHeader {
  background-color: $primary-color;
  color: $white-color;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;  // Reduce padding to bring the content closer together
  font-weight: bold;
}

.leaderboardRow {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;  // Reduce padding for the rows as well
  border-bottom: 1px solid $border-color;

  &:nth-child(even) {
    background-color: $background-color;
  }

  color: #000000;  // Ensure the text color remains black
}

.leaderboardCell {
  flex: 1;
  padding: 0.25rem 0.5rem;  // Further reduce padding within the cells for a tighter layout

  // Adjust column widths
  &:nth-child(1) { // Rank
    width: 10%; 
    text-align: left;
  }
  
  &:nth-child(2) { // Name
    width: 35%;
    text-align: left;
  }

  &:nth-child(3) { // Points
    width: 20%;
    text-align: right;
  }

  &:nth-child(4) { // Winner
    width: 35%;
    text-align: right;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.pageButton {
  background-color: $button-background-color;
  color: $primary-color;
  padding: 0.5rem 1rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  margin: 0 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s, color 0.3s;

  &:hover {
    background-color: $background-color;
    transform: $hover-transform;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    color: $primary-color;
  }
}

.activePage {
  background-color: $primary-color;
  color: $white-color;
  pointer-events: none;
}.alreadySubmittedMessage {
  text-align: center;
  color: $primary-color;
  font-size: 1rem;
  font-weight: 600;
  margin: 2rem 0;

  a.emailLink {
    color: $secondary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  button.resetButton {
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
    background-color: $secondary-color;
    color: $white-color;
    border: none;
    border-radius: $button-radius;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      background-color: darken($secondary-color, 10%);
    }
  }

  @media (max-width: 600px) {
    font-size: 0.875rem;
  }
}

.picksList {
  list-style: none;
  padding: 0;
  max-height: 50vh;
  overflow-y: auto;
  color: $text-color; // Ensures text color is consistent

  @media (max-width: 600px) {
    max-height: 40vh;
    overflow-x: hidden;
  }
}

.pickItem {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }

  .pickControls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    font-size: 1.25rem;
  }

  .removeButton {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: red;
  }
}

.pickItemNumber {
  font-weight: bold;
  margin-right: 1rem;
  color: $text-color; // Ensures text color is consistent
}

.sharePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.sharePopupContent {
  background: $white-color;
  padding: 2rem;
  border-radius: $container-radius;
  width: 90%;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  color: $text-color; // Ensures share popup content text color is consistent

  @media (max-width: 600px) {
    padding: 1rem;
    max-width: 95%;
    overflow-x: hidden;
  }
}

.shareText {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  font-size: 1rem;
  margin-bottom: 1rem;
  color: $text-color; // Ensures share text color remains consistent
}

.copyButton,
.tweetButton,
.shareButton,
.closeButton {
  background-color: $button-background-color;
  color: $primary-color;
  padding: 0.75rem 1.5rem;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s, color 0.3s;

  &:hover {
    background-color: $background-color;
    transform: $hover-transform;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    color: $primary-color;
  }
}
.formBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
  .tieBreaker, .secondTieBreaker {
    font-size: 0.8rem; // Smaller font size
    color: $subtitle-color; // Adjust text color if needed
    width: 100%;
    margin-bottom: 0.5rem; // Reduce margin to save space
  
    input {
      padding: 0.75rem;
      border: 1px solid $border-color;
      border-radius: $button-radius;
      font-size: 1rem;
      width: 100%;
      color: $text-color; 
      background-color: $white-color;
  
      &:focus {
        border-color: $primary-color;
      }
    }
  
    label {
      margin-bottom: 0.25rem; // Reduce space between label and input
      color: $subtitle-color;
      font-size: 0.75rem; // Smaller label font size
      display: block;
    }
  
    &.dark-mode {
      color: $text-color-dark;
  
      input {
        background-color: $background-color-dark;
        border-color: $border-color-dark;
        color: $text-color-dark;
      }
  
      label {
        color: $subtitle-color-dark;
      }
    }
  }
}
.listView {
  list-style-type: none;
  padding: 0;
  margin: 0 auto; // Center the list within its container
  max-width: 600px; // Optional: Limit the width to make it easier to center
  display: flex;
  flex-direction: column;
  gap: 0.5rem; // Space between list items

  @media (max-width: 600px) {
    gap: 0.25rem; // Reduce gap on mobile
    padding: 0 1rem; // Add padding on mobile to avoid touching the screen edges
  }
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  background-color: $white-color;
  border: 1px solid $border-color;
  border-radius: $button-radius;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s, border-color 0.3s;
  position: relative;

  &:hover {
    background-color: $background-color;
    box-shadow: 0 4px 8px $shadow-color;
    transform: $hover-transform;
  }

  &.dark-mode {
    color: #000000; // Force text color to black in dark mode
    background-color: $background-color-dark;
    border-color: $border-color-dark;

    .viewProfileLink {
      color: $primary-color;
    }
  }

  @media (max-width: 600px) {
    padding: 0.5rem 0.75rem;
  }
}

.listItemNumber {
  font-weight: bold;
  margin-right: 0.5rem;
  color: $text-color;

  &.dark-mode {
    color: #000000; // Ensure the number is black in dark mode
  }
}


.listItemNumber {
  font-weight: bold;
  margin-right: 0.5rem;
  color: $text-color;
}
.playerNameContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;

  span {
    color: #000000; // Force the text to be black in both light and dark modes
  }
  
  &.dark-mode span {
    color: #000000; // Ensure the text color remains black in dark mode
  }
}

.viewProfileLink {
  margin-left: 1rem;
  font-size: 0.875rem;
  color: $primary-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
}

.selectionNumber {
  background-color: $secondary-color;
  color: $white-color;
  padding: 0.25rem 0.5rem;
  border-radius: $button-radius;
  font-weight: bold;
  font-size: 0.875rem;
  min-width: 24px; // Ensure it has a consistent size
  text-align: center; // Center the number inside the box

  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
}
.footer {
  text-align: center;
  padding: 1rem 0;
  background-color: $white-color;
  border-top: 1px solid $border-color;
  margin-bottom: 40px; // Default space for desktop and larger screens

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 60px; // Increased space for mobile devices
  }
}
.noDataMessage {
  text-align: center;
  font-size: 1.25rem;
  color: $subtitle-color;
  margin: 2rem 0;
  padding: 1rem;
  background-color: lighten($background-color, 10%); // Slightly lighter background
  border-radius: $container-radius;
  box-shadow: 0 4px 8px $shadow-color;

  @media (max-width: 600px) {
    font-size: 1rem;
    padding: 0.75rem;
    margin: 1.5rem 0;
  }

  &.dark-mode {
    color: $text-color-dark;
    background-color: lighten($background-color-dark, 10%);
    box-shadow: 0 4px 8px $shadow-color-dark;
  }
}
.newEntryLink {
  color: $primary-color;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.subtitle {
  text-align: center;
  margin: 1.5rem 0;
  color: $subtitle-color;
  font-size: 1rem;

  a {
    color: $primary-color;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
    }
  }

  @media (max-width: 600px) {
    font-size: 0.875rem;
    margin: 1rem 0;
  }
}
.ellipsis {
  display: inline-block;
  margin: 0 5px;
  color: $subtitle-color; // Or any color you prefer
}
.subHeader {
  text-align: center; // Center the text
  margin: 1rem 0; // Adjust the margin for appropriate spacing
  color: $subtitle-color; // Use the subtitle color for consistency
  font-size: 1rem; // Make it slightly larger than normal text for emphasis
  font-weight: 500; // Make the font weight medium for a balanced appearance

  @media (max-width: 600px) {
    font-size: 1rem; // Slightly smaller on mobile for better readability
    margin: 0.75rem 0; // Adjust the margin for mobile devices
  }
}