.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: bold;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: red;
}
