:root {
    --background-color: #f8f9fa;
    --primary-text-color: #212529;
    --secondary-text-color: #495057;
    --primary-button-color: #007bff;
    --button-hover-color: #0056b3;
    --border-color: #ced4da;
    --button-text-color: #fff;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --image-shadow: rgba(0, 0, 0, 0.15);
    --toggle-border-color: #ccc;
    --toggle-bg-color: #f0f0f0;
    --toggle-hover-bg-color: #e7e7e7;
    --filter-button-bg-color: #ffffff;
    --filter-button-selected-bg-color: #007bff;
    --filter-button-text-color: #212529;
    --filter-button-border-color: #ccc;
}

.dark-mode {
    --background-color: #212529;
    --primary-text-color: #f8f9fa;
    --secondary-text-color: #adb5bd;
    --primary-button-color: #6c757d;
    --button-hover-color: #495057;
    --border-color: #343a40;
    --button-text-color: #f8f9fa;
    --shadow-color: rgba(0, 0, 0, 0.5);
    --image-shadow: rgba(0, 0, 0, 0.8);
    --toggle-border-color: #495057;
    --toggle-bg-color: #343a40;
    --toggle-hover-bg-color: #495057;
    --filter-button-bg-color: #343a40;
    --filter-button-selected-bg-color: #007bff;
    --filter-button-text-color: #f8f9fa;
    --filter-button-border-color: #495057;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: var(--background-color);
    color: var(--primary-text-color);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    color: var(--primary-button-color);
    font-size: 28px;
    font-weight: bold;
    margin: 20px 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px var(--shadow-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto;
}

.dark-mode .form {
    background-color: #343a40;
}

label {
    font-weight: bold;
    color: var(--secondary-text-color);
    display: flex;
    align-items: center;
}

.input, .checkbox {
    flex-grow: 1;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:focus, .checkbox:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
}

.button {
    cursor: pointer;
    padding: 10px 15px;
    color: var(--button-text-color);
    background-color: var(--primary-button-color);
    border: none;
    border-radius: 0.25rem;
    transition: background-color 0.2s;
}

.button:hover {
    background-color: var(--button-hover-color);
}

.results {
    margin-top: 20px;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
}

.resultsList {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 15px;
    justify-items: center;
}

.resultItem {
    background-color: #e9ecef;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 2px 4px var(--shadow-color);
    width: 100%;
    max-width: 320px;
}

.dark-mode .resultItem {
    background-color: #495057;
}

.imageContainer {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px var(--image-shadow);
}

.playerImage {
    width: 100%;
    height: auto;
    display: block;
}

.filterRow {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
}

.teamLogo {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.filterButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--filter-button-bg-color);
    border: 1px solid var(--filter-button-border-color);
    color: var(--filter-button-text-color);
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s, border-color 0.2s;
}

.filterButton:hover {
    background-color: var(--button-hover-color);
    border-color: var(--button-hover-color);
}

.filterButtonSelected {
    background-color: var(--filter-button-selected-bg-color);
    color: var(--button-text-color);
    border-color: var(--filter-button-selected-bg-color);
}

.navbar {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px 0;
}

.sport-picker {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

@media (min-width: 768px) {
    .filterRow {
        padding: 0;
    }
    .form {
        width: 80%;
        max-width: 720px;
    }
    .input, .checkbox {
        max-width: 100%;
    }
}
