@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html,
body {
  -webkit-overflow-scrolling: touch !important;
  // overflow: auto !important;
} 

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  margin-top: 0px;
  // padding-top: 10px !important;
  box-sizing: border-box;
  line-height: 1.25;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0; /* Add this line to remove any additional padding */
  width: 100%; /* Change width to 100% */
  max-width: 1200px;
  box-sizing: border-box;
}

.navbar {
  position: relative; /* Change from fixed to relative */
  top: 0;
  left: 0; /* Make sure it starts from the left */
  width: 100%; /* Stretch it to full length */
  height: 60px;
  // background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Padding on left and right side */
  margin: 0; /* No extra margin */
  // color: #fff;
  z-index: 1; /* Lowered the z-index value */
}
.navbar.hide {
  display: none;
}

.parent {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 150px);
  grid-template-rows: repeat(3, 150px);
}

.parent>div {
  padding: 10px;
  background-color: #EFCB68;
  border-radius: 5px;
  display: grid;
  place-items: center;
}

.parent>div {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.search-result-active {
  background-color: #e6e6e6;
}


.header-text {
        padding-right: 5px;
          padding-left: 5px;
  text-align: center;
    // TODO desktop should be 20, mobile should be like 16
    font-size: 18px;
    white-space: pre-line;
}
                            
.placeholder-header {
  min-height: 90px;
}

.quiz-header {
  height: 0px;
}



/* Rest of the CSS */



.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
}

.player-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  height: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.player-name-container {
    z-index: 100;
      color: white;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(10px);
      max-height: 20px;
      width: 100%;
}

.player-image-container {
  width: 100%;
  // margin-top: 10px;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
  overflow: hidden;
}

.player-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.player-name {
  color: black;
  text-align: center;
  align-self: flex-end;
  width: 100%;
  font-size: 12px;
}



.box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  // background-color: #fff;
  cursor: pointer;
  transition: all .2s ease-in-out;
  position: relative;
  aspect-ratio: 1/ 1;
  text-align: center;
  flex-direction: column;
  color: #000;
  box-shadow: 0 0 0 1px #c8c8c8;
  box-sizing: content-box;
  min-width: 0;
  min-height: 0;

}

// .box:hover {
//   opacity: 0.8;
// }

// .box.selected {
  // background-color: #d4d4d4;
// }

// .box.correct {
  // background-color: #fff;
  // text-align: center; /* This ensures the text is centered horizontally */
  // display: flex;
  // align-items: center; /* This will center content vertically */
  // justify-content: center; /* This will center content horizontally */
  // flex-direction: column; /* This stacks content vertically */
// }

.header, .row-header {
  // aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #eee;
  font-weight: bold;
}


// .row-header {
//   width: 120px;
//   padding-right: 10px;
// }


.remaining-guesses {
  margin-top: 20px;
  font-weight: bold;
}

.popup {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  // width: 100%;
  // height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

::placeholder {
  color: #6f6f6f;
}

.search-input:focus {
  outline: none;
}

.popup-content {
  background-color: #fefefe;
  margin: 15% auto;
  // padding-top: 40px;
  padding: 10px;
  border: none;
  width: 80%;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.results {
  // margin-top: 10px;
  overflow: auto;
}

.team-logo {
  height: 90px;
}

.team-logo-stats {
  height: 80px;
}

body {
  margin: 0;
  // padding-top: 40px;
  box-sizing: border-box;
}

.rowHeaderText {
  text-align: center;
  white-space: pre-line;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.rowHeaderTextStats {
  text-align: center;
  white-space: pre-line;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.links {
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  min-height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) { /* Example breakpoint for mobile devices */
  .links {
    flex-wrap: nowrap;
  }
}.sportLink {
  display: block;
  width: calc(20.66% - 5px); /* For 6 items across on desktop */
  box-sizing: border-box;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  color: black; /* Default color for light mode */
  text-align: center;
  transition: transform 0.2s ease, color 0.2s ease;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  margin-right: 5px;
  background-color: #ffffff; /* Background for light mode */
}

/* Dark Mode Styles */
body[data-theme="dark"] .sportLink {
  color: white; /* Text color for dark mode */
  background-color: #333333; /* Dark background for dark mode */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.sportLink:hover, .sportLink:active {
  transform: scale(0.98);
  color: #555; /* Hover color in light mode */
}

body[data-theme="dark"] .sportLink:hover, body[data-theme="dark"] .sportLink:active {
  color: #ddd; /* Lighter hover text color in dark mode for better visibility */
  background-color: #222222; /* Slightly lighter background on hover in dark mode */
  border-color: rgba(255, 255, 255, 0.3);
}

.sportLink.selected {
  font-weight: bold;
  color: #007aff; /* Selected color in light mode */
}

body[data-theme="dark"] .sportLink.selected {
  color: #0099ff; /* Selected color in dark mode */
}


/* Remove right margin for every sixth item on desktop */
.sportLink:nth-child(6n) {
  margin-right: 0;
}



.link-top-row, .link-second-row {
  flex: 1; 
  margin: 10px; 
  padding: 10px; 
}

@media (max-width: 768px) {
  .link-top-row {
    flex-basis: calc(20% - 10px);  // or any desired width that fits your design
  }
}


.sport-picker {
  margin-top: 10px;
  width: 100%;
  max-width: 700px;
  text-align: center;
  overflow-x: auto;          // Enables horizontal scrolling
  white-space: nowrap;       // Prevents items from breaking to the next line
  -webkit-overflow-scrolling: touch;  // Better scrolling on iOS
}
.sportLink, .sportLinkTopLeft {
  white-space: normal;  // This will allow the text inside the boxes to wrap.
}
.sportLink, .sportLinkTopLeft {
  height: auto;
}


.sportLinkTopLeft {
  display: block;
  width: auto;
  box-sizing: border-box;
  padding: 10px 16px;
  text-decoration: none;
  color: black;
  text-align: center;
  transition: transform 0.2s ease, color 0.2s ease;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
  background-color: #E8FCE8; /* light green background */

  &:hover, &:active {
    transform: scale(0.98);
    color: #007F00; /* darken the text color a bit on hover */
  }

  &.selected {
    font-weight: bold;
    color: #007aff;

    body[data-theme="dark"] &.selected {
      color: #0099ff;
    }
  }
}

// stack special categories
/* Responsive design for mobile screens */
// @media (max-width: 600px) {
//  .sportLink {
//     width: calc(33.33% - 5px); /* For 3 items across on mobile */
//     margin-right: 5px;
//   }
// 
  /* Remove right margin for every third item on mobile */
 //  .sportLink:nth-child(3n) {
//     margin-right: 0;
//   }
// }


.myLink {
  border-radius: 8px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  border: 10px;
  border-color: black;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navLink {
  background-color: transparent;
  // border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  padding: 3px 20px;
  text-decoration: none;
  margin: 0px;
  width: 31%;
}

.navLink.selected {
  border: none;
  // border-bottom: 5px solid #8EE7AE; 
  background-color: #8EE7AE;
  color: black;
  // color: #656565;
}

.navbar-logo {
  height: 30px;
}

.navbar ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar ul li {
  padding: 10px;
}

.twitter {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  // color: #fff;
}

.navbar a:hover {
  text-decoration: underline;
}

.navbar-icon {
  width: 18px;
  height: 18px;
  fill: black;
}

.Modal {
  width: 80%;
  margin: 5% auto;
  height: 80%;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.navbar {
  position: relative;  /* change from fixed to relative */
  top: 0;
  left: 0; /* Make sure it starts from the left */
  width: 100%; /* Stretch it to full length */
  height: 60px;
  // background-color:  #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Padding on left and right side */
  margin: 0; /* No extra margin */
  // color: #fff;
  z-index: 1; /* Lowered the z-index value */
}
.navbar.hide { /* class that will be added when a popup is open */
  display: none;
}


.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar ul li {
  margin-left: 20px; /* Left margin for each list item */
  margin-right: 20px; /* Right margin for each list item */
  padding: 10px; /* Padding for each list item */
}


.navbar ul li a:hover {
  color: #555555;
}
.navbar-logo {
  height: 30px;
}
input[type="text"], input[type="number"], textarea {
  font-size: 16px; /* Change the font size according to your preference */
  -webkit-text-size-adjust: 100%; /* Disable zoom on input focus */
}
.no-zoom {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
}
.green {
  background-color: #8EE7AE;
}
.box.green{
  background-color: #8EE7AE;

}

.game-over-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.game-over-container-popup {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 15px;
}

.scoreText {
  color: #212121;
  margin-bottom: 20px;
}

.share-copy-modal {
  text-align: center;
}


.share-button {
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  background-color:#7ADA91;
  border: none;
  border-radius: 25px;
  // box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 3px;
  color: black;
  font-weight: bold;
}

.share-button:hover {
  background-color: #069a21;
}

.share-button:active {
  transform: translateY(2px);
}
.share-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
button {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  text-decoration: none;
margin: 5px;
}
.disclaimer {
  font-style: italic;
  font-size: 12px; // or whatever size you prefer
}


.tapTargetEnhanced {
  padding: 15px 10px; 
}

button:hover {
  text-decoration: underline;
}

button:focus {
  outline: none;
}
.button.active {
  background-color: #007BFF; /* Blue background color */
  color: white; /* White text color */
  box-shadow: 0 4px 8px rgba(0,123,255,0.2); /* Box shadow for depth */
}
body[data-theme="dark"] button {
  background-color: #333;
  border: 1px solid #aaa;
  color: #eee;
}

body[data-theme="dark"] button:hover {
  background-color: #444;
  border: 1px solid #aaa;

  text-decoration: underline;
}

.game-mode-button.active {
  background-color: #1C6376;
  color: white;
}

.grid-container {
  width: 100%;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-auto-rows: 1fr;
  grid-gap: 0px;
  width: 100%;
  max-width: 800px;
  max-height: 800px;
  height: auto;
  margin: 0 auto;
  margin-top: 20px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  padding-right: 160px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-auto-rows: 1fr;
  grid-gap: 0px;
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  height: auto;
  margin: 0 auto;
  margin-top: 10px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  padding-right: 110px;
}


/* Add new CSS rules for reducing white space between game mode buttons and grid */
.game-mode-buttons {
  margin-top: 20px; /* Add margin between game mode buttons and grid */
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-mode-button {
  margin: 5px; /* Reduce margin between game mode buttons */
}



.sponsorship-link, .privacyLink {
  display: inline-block; /* This will allow the margin to take effect */
}
.sponsorship-link, .privacyLink a {
  text-decoration: none; /* Remove underline */
  border-bottom: 1px solid #2F4858; /* Add underline */
}
.firstLink {
  margin-right: 20px; /* Adjust the value to your liking */
}
.rarity {
  align-self: flex-start;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 2px;
  padding-right: 2px;
  z-index: 100;
  color: white;
  background-color:rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

.pft-nav-link:link {
      text-decoration: none !important;
    }
  
.pft-nav-link:visited {
      text-decoration: none !important;
    }
  
.pft-nav-link:hover {
      text-decoration: none !important;
    }
  
.pft-nav-link:active {
      text-decoration: none !important;
    }



.quiz-sporcle-container {
    display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      /* Add this line to remove any additional padding */
      width: 100%;
      /* Change width to 100% */
      max-width: 1200px;
      box-sizing: border-box;
  // display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 90%;
  //   margin: 0 auto;
  //   max-width: 1200px;
  //   box-sizing: border-box;

  header2 {
    text-align: center;
    margin-bottom: 20px;

    .app-logo {
      width: 100px;
      margin-bottom: 15px;
    }
  }

  .timer {
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
    color: #d43f00;
  }

  .search-section {
    margin-bottom: 20px;

    .search2-input {
      width: 100%;
      padding: 10px 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;

      &:focus {
        border-color: #007aff;
      }
    }

    .search-results {
      margin-top: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;

      .search-result-item {
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // &:hover {
        //   background-color: #e6e6e6;
        // }
      }
    }
  }
    .square-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  
    .square {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      /* This ensures a square aspect ratio */
      overflow: hidden;
    }
  
    .square-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* Aspect-fill effect */
      
      /* Add your desired blur effect here */
    }

  .guessed-players-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;

    .player-item {
      // padding: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      text-align: center;
      position: relative;
      max-width: 200px;


      .seasons-top-right {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.7rem; // Adjust this size accordingly
        opacity: 0.6; // This will make it slightly faded so it's not too prominent
    }
      &.guessed {
        background-color: #c8e6c9;
      }
    }
  }
.score-section{
  width: 100%;
  text-align: center;
}
  .score-feedback {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .score {
      font-weight: bold;
      text-align: center;
    }

    .feedback {
      color: #d43f00;
    }
  }
    .timer-buttons {
      @media(max-width: 800px ) {
        min-width: 40%;
      }
    }
  .game-over-button {
    display: block;
    // margin: 30px auto;
    padding: 10px 30px;
    background-color: #d43f00;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    

    &:hover {
      background-color: #b52c00;
    }
  }
}
// DiscoverQuizzes.scss
// DiscoverQuizzes.scss
.timer-game-over-container {
  display: flex;
  width: 100%;
  justify-content: center; /* This will push the elements apart */
  align-items: center; /* Align vertically in the middle */
  margin-bottom: 20px; /* Add space below the container */
}


.sticky-container {
  top: 0;
  position: sticky;
  z-index: 100;  // Ensure it stays above other content
  // background-color: white;  // Make sure background is solid to avoid seeing content scroll under it
  padding: 10px 0;  // Some padding for breathing room
  border-bottom: 1px solid #e5e5e5;  // Optional, for a slight visual separation
  transition: all 0.3s ease-in-out;

  @media (min-width: 800px) {  // Reset for desktop
      position: relative; 
  }
}
.mobile-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: white; // adjust this color
  color: #333; // adjust this color

  .menu-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: inherit;
  }

  .navbar-logo {
    width: 30px; // adjust as needed
    height: 30px;
  }

  .navbar-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.blurred {
  filter: blur(5px);
}
/* Style for the share buttons container */
.share-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Style for the Copy Score button */
.share-buttons button {
  background-color: #ff5a5f; /* Airbnb red */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.share-buttons button:hover {
  background-color: #d1484b; /* Slightly darker red on hover */
}

/* Style for the Share on Twitter link */
.share-buttons a {
  background-color: #00acee; /* Twitter blue */
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.share-buttons a:hover {
  background-color: #0073a3; /* Slightly darker blue on hover */
}
/* Style for correct selection */
/* Style for the green border when square is guessed correctly */
/* Style for the green glow when square is guessed correctly */
.guessed-border {
  border: 8px solid #A2E5B2;
  animation: glow 1s infinite alternate; /* Add a glowing animation */
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px green; /* Start with a small green glow */
  }
  100% {
    box-shadow: 0 0 20px green; /* Increase the glow intensity */
  }
}



.diagonal-left,
.diagonal-right {
  position: absolute;
  width: 100%;
  height: 100%;
}

.diagonal-left {
  clip-path: polygon(0 0, 100% 0, 0% 100%);
}

.diagonal-right {
  clip-path: polygon(100% 0, 100% 100%, 0% 100%);
}

.left-image-background,
.right-image-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(30px);
  /* Center and crop the images */
}

.left-image,
.right-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Center and crop the images */
}
/* Add this CSS to your stylesheet */
.grid-link {
  background-color: #007bff; /* Change to your desired button color */
  color: #fff; /* Text color */
  padding: 8px 16px; /* Adjust padding as needed */
  border-radius: 20px; /* Adjust the border radius to make it rounded */
  text-decoration: none; /* Remove underlines for links */
}

.grid-link:hover {
  background-color: #0056b3; /* Change the hover color if desired */
}

.diagonal-container {
  position: relative;
  width: 150px;
  /* Set your desired width */
  height: 150px;
  /* Set your desired height */
  overflow: hidden;
  // background-color: #f7f7f7;

  -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
}

.quiz-card {
  flex: 0 0 auto; // This will prevent cards from growing or shrinking
  // width: 300px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  // transition: transform 0.2s ease;
  text-decoration: none;
  color: inherit;
}

.diagonal-container-small {
  position: relative;
  width: 200px;
  /* Set your desired width */
  height: 200px;
  max-width: 100%;
  max-height: 100%;
  /* Set your desired height */
  overflow: hidden;

  -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
}
@media (max-width: 800px) {
  .grid {
    padding-right: calc((90vw - 20px) / 5)
  }

  .stats-grid {
    padding-right: calc((90vw - 20px) / 5)
  }
}

@media (max-width: 600px) {
  .diagonal-container {
    position: relative;
    width: 150px;
    /* Set your desired width */
    height: 150px;
    /* Set your desired height */
    overflow: hidden;

    -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
  }

  .app {
    width: 95%;
    margin-top: 0;
  }

  .quiz-sporcle-container {
    width: 95%;
    margin-top: 0;
  }

  .rowHeaderText {
    font-size: 10px;
  }

  .rowHeaderTextStats {
    font-size: 10px;
  }

  .team-logo {
    width: 15vw;
    height: 15vw;
  }

  .team-logo-stats {
    width: 10vw;
    height: 10vw;
  }

  .box {
    font-size: 14px;
  }

  .header,
  .row-header {
    font-size: 16px;
  }



  // .row-header {
  //   width: 90px;
  //   padding: 0
  // }

  .remaining-guesses {
    font-size: 10px;
  }

  .popup-content {
    margin: 10% auto;
    width: 90%;
  }

  .navbar ul li {
    padding: 5px;
  }

  .navbar-logo {
    height: 25px;
  }

  .navbar-icon {
    width: 15px;
    height: 15px;
  }

  .game-mode-buttons {
    margin-top: 10px;
    /* Adjust margin-top for better spacing on mobile */
  }

  .navbar {
    width: 100%;
    padding: 0px;
    margin-top: 0;
  }


  .header-text {
    text-align: center;
    // TODO desktop should be 20, mobile should be like 16
    font-size: 14px;
    white-space: pre-line;
  }

  // .player-image-container {
  //   height: 100%;
  // }

  // .player-image {
  //   height: 100%;
  // }

  .player-name {
    font-size: 8px;
  }

  .rarity {
    font-size: 10px;
  }

  .placeholder-header {
    min-height: 15vw
  }

  .share-button {
    font-size: 12px;
  }
}
/* Custom CSS for Prime Gaming logo */
.prime-gaming-logo img {
  max-width: 100px; /* Set to desired width */
  height: auto; /* Maintains the aspect ratio */
}
#pmLink {        
  visibility: hidden;               
  text-decoration: none;        
  cursor: pointer;        
  background: transparent;        
  border: none;    
}

#pmLink:hover {        
  visibility: visible;        
  color: grey;    
} /* Add these styles to your CSS file */
#date-select {
  width: 100%; /* Ensures the select box is as wide as its container */
  height: 40px; /* Set a fixed height for the select box */
  background: white; /* Set a background color */
  color: black; /* Text color */
  border: 1px solid #ccc; /* Border for the select box */
  border-radius: 4px; /* Rounded corners for the select box */
}

#date-select:focus {
  outline: none; /* Removes default focus outline */
  border-color: #0056b3; /* Adds a blue border on focus */
}

/* Style options inside the select dropdown */
#date-select option {
  padding: 10px; /* Adds padding inside the option */
}
.team-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: .4rem; /* Adjust font size as needed */
  font-weight: bold;
  /* Centering adjustments for the left triangle */
  word-wrap: break-word;
  white-space: normal;
  max-width: 80%; /* Adjust as needed to fit within the diagonal space */
  padding: 1%; /* Add some padding to prevent text from touching the edges */


}

.diagonal-left .team-text {
  top: 33.333%; /* One third from the top of the container */
  left: 22.222%; /* One third from the left side of the container */
  transform: translate(-50%, -50%);
  z-index: 2;
}

.diagonal-right .team-text {
  bottom: 33.333%; /* One third from the bottom of the container */
  right: 22.222%; /* One third from the right side of the container */
  transform: translate(50%, 50%);
  z-index: 2;
}
/* Base styles for the button */
.button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  outline: none;
  border: none; /* No border by default */
}

/* Light mode styles */
body[data-theme="light"] .button {
  background-color: #f0f0f0; /* Light grey background for light mode */
  color: #333; /* Dark grey text for light mode */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Dark mode styles */
body[data-theme="dark"] .button {
  background-color: #333333; /* Dark grey background for dark mode */
  color: #ffffff; /* White text for dark mode */
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

/* Hover and Active States */
.button:hover {
  background-color: #e1e1e1; /* Lighter grey on hover for light mode */
}

body[data-theme="dark"] .button:hover {
  background-color: #444444; /* Lighter grey on hover for dark mode */
}

.button:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

body[data-theme="dark"] .button:active {
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.2);
}
