// src/PageHeader.module.scss

.appHeader {
  &.light {
    background-color: #fff; /* Light theme background color */
  }

  &.dark {
    background-color: #333; /* Dark theme background color */
  }
}

.navbar-link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: inherit; /* Use inherit to apply color from parent theme */

  &.light {
    color: #000; /* Light theme text color */
  }

  &.dark {
    color: #fff; /* Dark theme text color */
  }

  &:hover {
    text-decoration: none;
  }
}

.navbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 0;
  z-index: 1;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 30px;
  width: 30px;
}