.app-container {
    font-family: 'Inter', sans-serif;
    // color: #484848;
    // background-color: #f7f7f7;

    .app-header {
        // background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        nav {
            display: flex;
            justify-content: space-between;

            .nav-item {
                margin-right: 1rem;
                color: inherit;
                text-decoration: none;
                font-weight: bold;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .discover-outer {
        padding-top: 1rem;
        padding-bottom: 2rem;

        .discover-container {
            h2 {
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }

            .quizzes-slider {
                display: flex;
                align-items: flex-start;
                overflow-x: scroll; // This will make it horizontally scrollable
                gap: 1.5rem;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none; // Hide scrollbar for Chrome, Safari, and Opera
                }

                .quiz-card {
                    flex: 0 0 auto; // This will prevent cards from growing or shrinking
                    // width: 300px;
                    border-radius: 1rem;
                    overflow: hidden;
                    // transition: transform 0.2s ease;
                    text-decoration: none;
                    color: inherit;

                    // &:hover {
                    //     transform: translateY(-5px);
                    // }

                    // .quiz-thumbnail {
                    //     width: 100%;
                    //     height: 200px;
                    //     -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
                    //         box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
                    //     img {
                    //         width: 100%;
                    //         height: 100%;
                    //         object-fit: cover;
                    //     }
                    // }

                    h3 {
                        padding: 1rem;
                        font-size: 1.2rem;
                        background-color: white;
                    }
                }
            }
        }
    }

    footer {
        padding: 1rem 2rem;
        background-color: white;
        box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
        font-size: 0.9rem;
    }
}
.quiz-card {
    border: 1px solid #ccc; /* This is a placeholder, replace with your border style */
    display: block;
    user-select: none;
    overflow: hidden;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out;
  }
  
  .quiz-card:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  