/* GeoGuess.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    font-family: 'Arial', sans-serif;
}

.startButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.startButton:hover {
    background-color: #45a049;
}

.gameArea {
    width: 80%;
    margin-top: 20px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 8px;
}

.playerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playerImage {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    object-fit: cover;
    margin-bottom: 20px;
}

.inputFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.inputFields input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton {
    padding: 10px 20px;
    background-color: #008CBA;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #007ba7;
}

.scores {
    font-size: 16px;
}

@media (max-width: 768px) {
    .gameArea {
        width: 95%;
    }

    .inputFields {
        grid-template-columns: 1fr;
    }
}
