/* lebron.module.css */
:root {
    --background-color: #FAFAFA;
    --text-color: #333;
    --accent-color: #007AFF; /* iOS Blue */
    --neutral-color: #CDCDCD;
    --correct-color: #4CD964; /* Correct Guess Green */
    --same-team-color: #FFCC00; /* Same Team Yellow */
    --same-number-color: #5AC8FA; /* Same Number Blue */
    --incorrect-color: #C7C7C7; /* Incorrect Guess Gray */
    --font-family: 'San Francisco', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --border-radius: 8px;
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .container {
    background: var(--background-color);
    color: var(--text-color);
    font-family: var(--font-family);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .heading {
    margin: 0;
    color: var(--accent-color);
    font-size: 24px;
  }
  
  .score {
    font-size: 20px;
    font-weight: bold;
  }
  
  .section, .correctSequenceModal {
    background: #FFFFFF;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 20px;
    width: 65%;
    
  }
  
  .actionButtons, .operations {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  button {
    background: var(--accent-color);
    color: #FFFFFF;
    border: none;
    padding: 10px 15px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: darken(var(--accent-color), 10%);
  }
  
  .submitButton, .giveUpButton {
    flex-grow: 1;
    margin: 0 5px;
  }
  
  .searchInput {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--neutral-color);
    border-radius: var(--border-radius);
    margin-bottom: 20px;
  }
  
  .playerList {
    list-style: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .playerItem, .selectedPlayerItem {
    padding: 10px;
    margin: 5px 0;
    background: var(--neutral-color);
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .selectedPlayerItem {
    background: var(--accent-color);
    color: white;
  }
  /* Adjustments within lebron.module.css for guess history layout */

.historyList {
    display: flex; /* Aligns items in a row */
    flex-wrap: wrap; /* Allows items to wrap onto the next line if space requires */
    justify-content: center; /* Centers items horizontally */
    margin-bottom: 20px; /* Provides spacing between each set of guesses */
  }
  
  .historyItem, .correct, .sameTeam, .sameNumber, .incorrect, .selectedPlayer, .selectedOperation {
    margin: 2px; /* Provides spacing around each item */
    padding: 8px 12px; /* Adjust padding for visibility */
    border-radius: var(--border-radius); /* Rounded edges for each item */
    display: inline-flex; /* Ensures items are inline and centered */
    align-items: center; /* Aligns items vertically in the center */
    font-size: 0.9rem; /* Adjusts font size for better fit */
  }
  
  /* Additional styling to differentiate between types of guesses */
  .selectedPlayer, .selectedOperation {
    background: var(--neutral-color); /* Background for selected items */
    color: #fff; /* Text color for better contrast */
  }
  
  .correct { background-color: var(--correct-color); color: #fff; }
  .sameTeam { background-color: var(--same-team-color); color: #fff; }
  .sameNumber { background-color: var(--same-number-color); color: #fff; }
  .incorrect { background-color: var(--incorrect-color); color: #fff; }
  
  .roundContainer {
    width: 100%; /* Ensures the container uses the full width */
  }
  
  .neutral {
    background: var(--background-color); /* Neutral background for equality sign */
    color: var(--text-color); /* Ensures text is readable */
    font-weight: bold; /* Makes the equality sign stand out */
  }
  
  @media (max-width: 768px) {
    .actionButtons, .operations {
      flex-direction: column;
    }
  
    .submitButton, .giveUpButton {
      width: 100%;
      margin: 5px 0;
    }
  }
  