@import "src/variables";
@import "src/mixins";

$theme: dark;

.profile {
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 10px;
    height: 10px;
    background-color: black;
    margin: 3px;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
