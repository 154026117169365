.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.timerDisplay {
    font-weight: bold;
    font-size: 1rem;
    /* Position it on the right */
    margin-left: auto; /* This will push the timer to the right side */
}

  
/* Updating existing styles for scoreCounter to match the sticky header */
.scoreCounter {
    font-size: 1rem; /* Adjust the size as necessary */
    color: #333; /* Dark color for contrast */
    font-weight: 600; /* Make it bold */
    margin-right: 20px; /* Spacing between score counter and possibly other elements */
  }
/* Updating the styles for the inputAndButtonContainer */

  .search-section {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
    flex-grow: 1; /* Allows input to grow and fill space */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

  
  .search-input:focus {
    border-color: #0077cc; /* Light blue border when focused */
  }
  .inputAndButtonContainer {
    position: relative; /* Anchor point for absolutely positioned children */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
}

.search-results {
    width: calc(100% - 2px); /* Subtracting the borders to fit container */
    max-height: 300px; /* Maximum height before scrolling */
    position: absolute; /* Positioned absolutely relative to the parent */
    top: 100%; /* Positioned right below the input field */
    left: 0; /* Aligned to the left edge of the parent container */
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Hides the horizontal scrollbar */
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px; /* Rounded bottom corners */
    z-index: 11; /* Stacks above other content */
    margin-top: 2px; /* Small gap between input and dropdown */
    border: 2px solid #ccc; /* Matches the input border */
    border-top: none; /* No border on the top to merge with input */
}

  
  .search-result-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .search-result-item:hover,
  .search-result-item.resultActive {
    background-color: #eef3f7; /* Light blue background on hover or active */
    font-weight: bold;
  }
  
  /* Timer and game control section, aligned to the right */
  .timer-controls {
    display: flex;
    align-items: center;
    font-size: 1.25rem; /* Match score counter font size */
  }

  /* See Answers button styling */
  .seeAnswersButton {
    padding: 6px 12px;
    background-color: #007bff; /* Sporcle blue, or adjust as needed */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;

  }
  /* If you have a start button, align its styling with the seeAnswersButton */
.startButton {
    padding: 6px 12px;
    background-color: #007bff; /* Sporcle blue, or adjust as needed */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  /* Responsive adjustments for mobile */
/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .stickyHeader {
      padding: 5px 10px;
    }
    
    /* Reduce the size of the elements within the sticky header on mobile */
    .scoreCounter,
    .timerDisplay,
    .seeAnswersButton {
      font-size: 0.875rem;
    }
    

        .top-bar {
      padding: 5px 10px; /* Reduced padding on mobile */
    }
  

    .input-container {
      padding: 0 5px; /* Reduced padding around input on mobile */
    }
  
    .quiz-input {
      padding: 8px; /* Smaller padding in the input field on mobile */
    }
  
    .seeAnswersButton {
      padding: 4px 8px; /* Smaller button padding on mobile */
    }
  }
  .stickyHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100; /* High enough to sit on top of other page elements */
    background-color: #fff; /* Assuming a white background */
    border-bottom: 1px solid #e0e0e0; /* A light border at the bottom */
    padding: 10px 20px; /* Padding to match Sporcle's layout */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow for depth */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
