@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

:root {
  --background-light: #f4f4f4;
  --background-dark: #121212;
  --text-color-light: #333;
  --text-color-dark: #f5f5f5;
  --primary-color: #0078d4;
  --secondary-color: #f28b82;
  --button-bg-light: #ffffff;
  --button-bg-dark: #333333;
  --modal-border-radius: 12px;
  --modal-padding: 24px;
  --modal-title-size: 1.75rem; /* Slightly larger for readability */
  --modal-text-size: 1.1rem; /* Increased for better readability */
  --item-bg-light: #fafafa;
  --item-bg-dark: #1e1e1e;
  --border-color-light: #e0e0e0;
  --border-color-dark: #333333;
  --selected-item-bg-light: #ddd;
  --selected-item-bg-dark: #2d2d2d;
  --shadow-light: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly more pronounced shadow */
  --shadow-dark: 0 4px 8px rgba(255, 255, 255, 0.1);
}
:root {
  /* Existing variables */
  --highlight-color: #ffcc00; /* Bright yellow as an example */
}

[data-theme="dark"] {
  /* Inverting colors for dark mode as you've done */
  --highlight-color: #ffcc00; /* The same or a different color for dark mode if needed */
}
:root {
  /* Other variables */
  --selected-item-bg-color-light-mode: #ddd; /* Existing light mode selected item background */
}

[data-theme="dark"] {
  /* Other dark mode variables */
  --selected-item-bg-color-dark-mode: #ffcc00; /* A bright yellow for better visibility in dark mode */
}
[data-theme="dark"] .selected {
  background-color: var(--selected-item-bg-color-dark-mode); /* Use the new dark mode variable */
  /* Keep the other properties the same */
}
[data-theme="dark"] .selected {
  background-color: var(--selected-item-bg-color-dark-mode); /* Bright color for dark mode */
  border-color: var(--highlight-color); /* Optional: to make the border stand out */
  color: var(--text-color-light); /* Ensure the text is readable on the new bright background */
}
:root {
  /* ... other variables ... */
  --selected-item-bg-color-light-mode: #ddd; /* Existing selection color for light mode */
}

[data-theme="dark"] {
  /* ... other dark mode variables ... */
  --selected-item-bg-color-dark-mode: #4caf50; /* A bright green for better visibility in dark mode */
}

[data-theme="dark"] .selected {
  background-color: var(--selected-item-bg-color-dark-mode); /* Use the new variable */
  border-color: var(--highlight-color); /* Adjust if you have a highlight color variable */
  color: var(--text-color-light); /* Assuming you have a variable for light text */
}

[data-theme="dark"] {
  --background-light: #121212;
  --background-dark: #f4f4f4;
  --text-color-light: #f5f5f5;
  --text-color-dark: #333;
  --modal-bg-light: #424242; /* Darker modal background for dark mode */
  --modal-bg-dark: #FFFFFF; /* Light modal background for light mode */
  --item-bg-light: #1e1e1e;
  --item-bg-dark: #fafafa;
  --secondary-color: #f28b82;

  --border-color-light: #333333;
  --border-color-dark: #e0e0e0;
  --selected-item-bg-light: #2d2d2d;
  --selected-item-bg-dark: #ddd;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  max-height: 70vh; /* Maximum height before scrolling */
  overflow-y: auto; /* Enables vertical scrolling */
  position: relative; /* For absolute positioning of the close button */
}

.modal-close {
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.modal p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.guess-emoji-row {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-button {
  background-color: #ffd700;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #cca700;
}

/* Additional classes for timer and game info */
.game-info {
  text-align: center;
  margin-bottom: 20px;
}

.timer {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}



.connections-game {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: var(--background-light);
  color: var(--text-color-light);
  border-radius: 10px;
  box-shadow: var(--shadow-light);
}

h1 {
  font-weight: 700;
  color: var(--primary-color);
}

.items-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-bottom: 25px;
}

.item {
  background-color: var(--item-bg-light);
  border: 2px solid var(--border-color-light);
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item:hover {
  transform: translateY(-3px);
}

.selected {
  background-color: var(--selected-item-bg-light); /* Light theme background */
  border: 2px solid var(--secondary-color); /* Primary color for border */
  box-shadow: 0 0 8px var(--highlight-color); /* Highlight color for a subtle glow effect */
}

[data-theme="dark"] .selected {
  background-color: var(--selected-item-bg-dark); /* Dark theme background */
  /* Border and shadow remain the same, as they're designed to be theme-independent */
}


button {
  background-color: var(--button-bg-light);
  color: var(--text-color-light);
  border: none;
  padding: 10px 20px;
  margin: 5px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background-color: var(--primary-color);
  color: var(--text-color-dark);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.status, .hints-section {
  margin-top: 20px;
  font-size: 16px;
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .items-container {
    grid-template-columns: repeat(3, 1fr); /* Adjusted for smaller screens */
  }

  .connections-game, .modal {
    width: 95%;
    padding: 10px; /* Adjust padding for tighter spaces */
  }
}

/* Focus states for accessibility */
button:focus, .item:focus {
  outline: 3px solid var(--primary-color); /* Enhance focus indication */
}

/* Selection color for text */
::selection {
  background: var(--secondary-color);
  color: var(--text-color-dark);
}

/* Hints section styling */
.hints-section .hint-category {
  background: linear-gradient(to right, var(--background-light), var(--item-bg-light));
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: var(--shadow-light);
}

.hints-section .category-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.hints-section .hints, .hints-section .hint-button {
  margin: 5px 0;
}

.hint-button {
  background-color: var(--button-bg-light);
  color: var(--text-color-light);
  border-radius: 20px;
  padding: 8px 16px;
  box-shadow: var(--shadow-light);
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
}

.hint-button:hover {
  background-color: var(--primary-color);
  color: var(--text-color-dark);
}

.hint-button:disabled {
  background-color: var(--border-color-light);
  color: var(--text-color-light);
  cursor: not-allowed;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  body, .connections-game, .item,  button {
    background-color: var(--background-dark);
    color: var(--text-color-dark);
    border-color: var(--border-color-dark);
  }

  .item{
    box-shadow: var(--shadow-dark);
  }

  .selected {
    background-color: var(--selected-item-bg-dark);
  }



  .hints-section .hint-category {
    background: linear-gradient(to right, var(--background-dark), var(--item-bg-dark));
    box-shadow: var(--shadow-dark);
  }

  .hint-button {
    background-color: var(--button-bg-dark);
    color: var(--text-color-light);
    box-shadow: var(--shadow-dark);
  }

  .hint-button:hover {
    color: var(--text-color-light);
  }
}

@media (max-width: 768px) {
    .items-container {
      grid-template-columns: repeat(4, 1fr); /* 4 items per row */
      gap: 10px; /* Smaller gap for mobile */
    }
  
    .item {
      padding: 10px; /* Reduced padding */
      margin: 0; /* Ensure no margins are affecting the layout */
      min-width: 0; /* Override any minimum widths set elsewhere */
    }
}
.hint-category.found {
    background-color: #90ee90; /* Light green; adjust as needed */
    /* Other styling properties to make it distinct */
  }
  .items-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 items per row */
    gap: 10px; /* Adjusted gap for spacing */
    padding: 10px; /* Padding around the entire grid */
  }
  
  .item {
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    height: 100px; /* Fixed height for text-only view */
    border-radius: 5px;
    border: 1px solid var(--border-color-light);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .item:hover {
    background-color: var(--item-bg-light-hover); /* Lighter background on hover */
  }
  
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    /* Additional styles for item */
  }
  
  .item img {
    width: 100%; /* Ensure the image covers the item area */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the area, clipping the image if necessary */
    transition: opacity 0.3s ease; /* Smooth transition for any opacity changes */
  }
  
  .item.selected::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 4px solid var(--secondary-color); /* Primary color for the border */
    box-shadow: 0 0 8px var(--highlight-color); /* Highlight color for the shadow */
    border-radius: 4px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 1;
}


  .item-showing-image img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto; /* Adjust based on the aspect ratio of your images */
    height: auto; /* Let the height be automatic to maintain image proportions */
    max-height: 100%; /* Ensure the image does not exceed the item container's height */
    object-fit: contain; /* This will ensure the image is scaled down to fit, without cropping */
    transform: translate(-50%, -50%); /* Center the image within the container */
  }
  
  .item-showing-image img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto; /* Adjust based on the aspect ratio of your images */
    height: auto; /* Let the height be automatic to maintain image proportions */
    max-height: 100%; /* Ensure the image does not exceed the item container's height */
    object-fit: contain; /* This will ensure the image is scaled down to fit, without cropping */
    transform: translate(-50%, -50%); /* Center the image within the container */
  }
  :root {
    --category-color-1: #FF0000; /* Example red */
    --category-color-2: #00FF00; /* Example green */
    --category-color-3: #0000FF; /* Example blue */
    --category-color-4: #ffae00; /* Example blue */

  }
  
  .hint-category {
    border: 2px solid; /* Set border but color will be determined by class */
    
  }
  
  .hint-category.category-color-1 {
    border-color: var(--category-color-1);
  }
  
  .hint-category.category-color-2 {
    border-color: var(--category-color-2);
  }
  
  .hint-category.category-color-3 {
    border-color: var(--category-color-3);
  }
  .hint-category.category-color-4 {
    border-color: var(--category-color-4);
  }
  /* Ensure you keep the rest of your CSS styles as is */
  @keyframes flashAnimation {
    0%, 100% { background-color: transparent; }
    50% { background-color: var(--primary-color); }
  }
  
  .hint-category.found {
    animation: flashAnimation 1s;
  }
/* Add this CSS to your global stylesheet or component CSS file */
.toastify-toast-container {
    /* Center the toast notifications horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  
    /* Position the notifications in the center of the screen */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }
  
  .toastify-toast {
    /* Adjust the width of the toast notification */
    width: 320px;
  
    /* Add any additional styles you want for the toast */
    /* Example styles: */
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  }
  .game-ended {
    pointer-events: none;
    opacity: 0.5;
  }
  

/* Light mode specific styles */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
}

/* Dark mode specific styles */
[data-theme='dark'] {
  --background-color: #2c2c2e;
  --text-color: #ffffff;
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

/* Base styles */
body, button {
  font-family: 'Noto Sans', sans-serif;
}
