.green {
  background-color: #8EE7AE;
}
.box.green{
  background-color: #aeabab;

}

.pgalogo {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 50% !important; 
  max-width: 300px !important;
  height: auto !important;
  opacity: 0.15 !important;
  pointer-events: none !important;
  z-index: 1 !important;
}


.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Updated to 3 columns */
  grid-gap: 0px;
  width: 100%;
  max-width: 800px;
  max-height: 800px;
  height: auto;
  margin: 0 auto;
  margin-top: 10px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  padding-right: 160px;
}

.statsGrid {
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-gap: 0px;
width: 100%;
max-width: 600px;
max-height: 600px;
height: auto;
margin: 0 auto;
margin-top: 10px;
grid-column-gap: 1px;
grid-row-gap: 1px;
padding-right: 110px;
}
.twitter {
text-decoration: none;
align-items: center;
justify-content: center;
}

@media (max-width: 800px) {
.grid {
  padding-right: calc((90vw - 20px) / 4)
}

.statsGrid {
  padding-right: calc((90vw - 20px) / 4)
}
}
