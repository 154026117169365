/* CSS variables for light and dark modes */
:root {
  --background-color: #fafafa;
  --card-background: #ffffff;
  --border-color: #e0e0e0;
  --text-color: #333;
  --subtext-color: #9e9e9e;
  --info-block-bg: #f5f5f5;
  --info-block2-bg: #ffffff;

  --button-bg: #007aff;
  --button-text-color: #ffffff;
  --button-hover-bg: #0056b3;
  --suggestions-bg: #fff;
  --suggestion-item-hover-bg: #f0f0f0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121212;
    --card-background: #1e1e1e;
    --border-color: #333;
    --text-color: #e0e0e0;
    --subtext-color: #ccc;
    --info-block-bg: #2a2a2a;
    --info-block2-bg: #ffffff;

    --button-bg: #0056b3;
    --button-text-color: #fff;
    --button-hover-bg: #003a70;
    --suggestions-bg: #2a2a2a;
    --suggestion-item-hover-bg: #333;
  }
}
:root {
  --filter-button-bg-color: #f0f0f0;
  --filter-button-border-color: #ccc;
  --filter-button-text-color: #333;
  --button-hover-color: #e0e0e0;
  --filter-button-selected-bg-color: #007bff;
  --primary-color: #0066cc;
  --primary-hover-color: #0056b3;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--background-color);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 10px;
  min-width: 60%;
}

.card {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  background: var(--card-background);
  box-shadow: 0 4px 10px rgba(0,0,0,0.05);
  overflow-y: auto;
  position: relative;
  padding-bottom: 200px;
}

@media (max-width: 600px) {
  .card {
    max-width: 100%;
    padding-bottom: 100px;
  }
}

.title {
  font-size: 2em;
  color: var(--text-color);
  font-weight: 300;
  margin-bottom: 0.5em;
}

.infoBlock {
  text-align: left;
  padding: 1em;
  background-color: var(--info-block-bg);
  margin: 1em 0;
  border-radius: 6px;
  flex: 1; /* Ensures the blocks take up equal space when side by side */

}

/* Container to hold both info blocks */
.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Ensure items align properly if they are of different heights */
}
.info {
  font-size: 0.85em;
  color: var(--subtext-color);
  text-transform: uppercase;
  margin: 0;
}
.infoBlock2 {
  text-align: left;
  padding: 1em;
  background-color: var(--info-block2-bg);
  margin: 1em 0;
  border-radius: 6px;
  display: inline-block; /* Change display to inline-block */
  vertical-align: top; /* Aligns elements from the top */
  width: calc(50% - 1em); /* Adjust width to fit two blocks side by side with margin */
}

.infoValue {
  font-size: 1.2em;
  color: var(--text-color);
  margin-top: 0.3em;
}

.teamLogo {
  height: 50px;
  margin: 0.5em;
  vertical-align: middle;
}
.collegeLogo {
  height: 50px;
  margin: 0.5em;
  vertical-align: middle;
}

.teamLogos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.guessForm {
  margin-top: 2em;
  position: relative;
}

.formControls {
  display: flex;
  align-items: center;
}

.guessInput {
  flex-grow: 1;
  width: auto;
  padding: 1em;
  margin-right: 0.5em;
  border-radius: 6px;
  border: 1px solid var(--button-bg);
  font-size: 1em;
  color: var(--button-text-color);
  box-sizing: border-box;
}

.guessButton {
  padding: 1em;
  border-radius: 6px;
  background-color: var(--button-bg);
  color: var(--button-text-color);
  border: none;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestions {
  position: absolute;
  width: calc(100% - 2em);
  max-height: 150px;
  overflow-y: auto;
  background-color: var(--suggestions-bg);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  top: calc(100% + 1em);
  left: 1em;
  z-index: 10;
}


.guessButton:hover {
  background-color: var(--button-hover-bg);
}.guessesLeft {
  color: #9e9e9e;
  font-size: 0.85em;
  margin-top: 1em;
  font-weight: 400;
}

.suggestionsList {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: var(--suggestions-bg);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border: 1px solid #ddd;
  border-radius: 4px;
  top: 100%;
  z-index: 10;
}

.suggestionItem {
  padding: 10px;

  cursor: pointer;
}

.suggestions {
  background: var(--suggestions-bg);
}

.suggestionItem:hover {
  background-color: var(--suggestion-item-hover-bg);
}

.playerName {
  flex-grow: 1;
}

.playerDetails {
  margin-right: 10px;
  color: #666;
}

.scoreboard {
    background-color: var(--background-color); /* Light background */
    color: var(--text-color); /* Dark text */
    padding: 10px 10px; /* Padding for inner spacing */
    border-radius: var(--button-border-radius); /* Rounded corners */
    box-shadow: var(--box-shadow); /* Soft shadow */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    gap: 20px; /* Space between items */
}

.scoreboard p {
    margin: 0; /* Reset margin */
    font-size: 14px; /* Font size for readability */
    color: var(--text-color); /* Text color for consistency */
}

.scoreboard p:first-child {
    font-weight: var(--button-font-weight); /* Bold for emphasis */
    color: var(--primary-color); /* Primary color for importance */
}


.filterRow {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
}

.teamLogo2 {
    width: 20px;    /* Set a fixed width for all logos */
    height: 20px;   /* Set a fixed height for consistency */
    vertical-align: middle; /* Align logos with the text vertically */
    margin-right: 5px; /* Adds a small space between the logo and the text */
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--filter-button-bg-color);
  border: 1px solid var(--filter-button-border-color);
  color: var(--filter-button-text-color);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
}

.filterButton:hover {
  background-color: var(--button-hover-color); /* Changes background on hover */
  border-color: var(--button-hover-color); /* Changes border color on hover */
}

.filterButtonSelected {
  background-color: var(--filter-button-selected-bg-color);
  color: var(--button-text-color);
  border-color: var(--filter-button-selected-bg-color); /* Ensures the border color matches the background */
}

/* GuessThePlayer.module.css */
.radio {
  color: var(--primary-color); /* Set the color of the radio button */
}

.radioLabel {
  color: var(--text-color); /* Text color for the label */
  margin-left: 8px; /* Space between the radio button and label */
  font-size: 0.95em; /* Slightly smaller font size for labels */
}

.formControl {
  margin-bottom: 15px; /* Space between form control groups */
}

.textField {
  margin-top: 10px; /* Space above the text field */
  width: 100%; /* Full width of its container */
  color: var(--text-color); /* Text color */
  border-color: var(--border-color); /* Border color */
}


/* Form Elements */
.section {
  margin-top: 20px;
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
}

.radioLabel, .checkboxLabel {
  display: block;
  margin: 10px 0;
  color: var(--text-color);
  font-size: 16px;
}

.filterRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}


/* Input and Button Styles */
.textField {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.button:hover {
  background-color: var(--primary-hover-color);
}.toastify-toast-container {
  /* Center the toast notifications horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  /* Position the notifications in the center of the screen */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.toastify-toast {
  /* Adjust the width of the toast notification */
  width: 320px;

  /* Add any additional styles you want for the toast */
  /* Example styles: */
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}.leaderboardModal {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  background-color: 'white';
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.leaderboardModalContent {
  max-height: 60vh; /* 60% of the viewport height */
  overflow-y: auto; /* Enables vertical scrolling if content is taller than the max-height */
  padding: 20px;
}

.leaderboardModalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Adjust the width as necessary */
  max-width: 600px; /* Maximum width of the modal */
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1050; /* Ensure it's above other content */
}


.leaderboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.tableContainer {
  max-height: 400px;
  overflow-y: auto;
}

/* Improve the look of the table */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #eaeaea;
}

/* Style for the buttons inside the modal */
.modalButton {
  margin-right: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.modalButtonPrimary {
  background-color: #0056b3;
  color: white;
}

.modalButtonSecondary {
  background-color: #6c757d;
  color: white;
}

.modalButtonPrimary:hover {
  background-color: #004494;
}

.modalButtonSecondary:hover {
  background-color: #565e64;
}

.modalButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}/* GuessThePlayer.module.css */
.leaderboardButton {
  margin: 0 10px;
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.leaderboardButtonAllTime {
  background-color: #008CBA; /* Blue */
}

.leaderboardButtonSelected {
  background-color: #DAA520; /* Goldenrod */
  color: black;
}
.leaderboardHeaderTitle {
  font-size: 24px; /* Larger font size for better visibility */
  font-weight: bold; /* Bold font for emphasis */
  color: #4A90E2; /* A distinct color, adjust based on your color scheme */
  text-align: center; /* Center alignment for better aesthetics */
  padding: 10px 0; /* Padding for spacing */
  margin-bottom: 20px; /* Space below the header */
}
.availablePlayersBox {
  padding: 3px 8px;  /* Slightly smaller padding */
  margin-left: 15px; /* Adjusted margin for closer proximity to the title */
  background-color: #f8f8f8; /* Very light grey background */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 4px; /* Slightly rounded corners */
  font-size: 12px; /* Smaller font size for subtlety */
  display: inline-block; /* Inline block for proper alignment */
  vertical-align: middle; /* Aligns vertically with the text of the title */
  color: #666; /* Dark grey text for less prominence */
}
.titleBar {
  display: flex;
  align-items: center; /* This ensures vertical alignment is centered */
}

.title {
  margin-right: 10px; /* Ensures spacing between the title and the icon button */
  align-items: center; /* This ensures vertical alignment is centered */

}
.leaderboardTableContainer {
  max-height: 400px; /* Adjust based on your needs */
  overflow-y: auto; /* Enables scrolling */
}
/* Add to your existing styles for the settings dialog container */
.dialogContent {
  padding: 20px;
  padding-bottom: 100px; /* Increase padding at the bottom */
}

/* Or directly to the button if it's the last element */
.saveButton {
  margin-bottom: 100px; /* Add sufficient margin at the bottom */
}
/* General modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 10; /* Ensures it sits on top of other content */
}

.leaderboardModalContainer {
  background: var(--card-background);
  border: 1px solid var(--border-color);
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.modalTitle {
  color: var(--text-color);
  margin-bottom: 20px;
  text-align: center;
}

.leaderboardModalContent {
  max-height: 400px;
  overflow-y: auto;
}

/* Styling for the table */
.leaderboardTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.leaderboardTable th,
.leaderboardTable td {
  border-bottom: 1px solid var(--border-color);
  padding: 8px;
  text-align: left;
}

.leaderboardTable th {
  background: var(--info-block-bg);
  color: var(--subtext-color);
}

.leaderboardTable td {
  background: var(--info-block2-bg);
  color: var(--text-color);
}

/* Buttons within the modal */
.button,
.loadMoreButton,
.closeButton {
  padding: 10px 20px;
  border: none;
  background: var(--button-bg);
  color: var(--button-text-color);
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: center;
}

.button:hover,
.loadMoreButton:hover,
.closeButton:hover {
  background: var(--button-hover-bg);
}

.closeButton {
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

/* Loading indicator style */
.loadingIndicator {
  text-align: center;
  padding: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .leaderboardModalContainer {
    width: 95%;
  }
}

@media (prefers-color-scheme: dark) {
  .leaderboardTable th,
  .leaderboardTable td {
    border-bottom-color: var(--border-color);
  }
}
