// game.module.scss

$primary-color: #174885;
$secondary-color: #ff6f00;
$background-color: #f0f0f0;
$background-color-dark: #222222;
$white-color: #ffffff;
$text-color: #174885;
$text-color-dark: #ffffff;
$subtitle-color: #999999;
$subtitle-color-dark: #cccccc;
$border-color: #d1d1d1;
$border-color-dark: #444444;
$shadow-color: rgba(0, 0, 0, 0.1);
$shadow-color-dark: rgba(0, 0, 0, 0.5);
$hover-transform: translateY(-2px);
$button-radius: 8px;
$container-radius: 16px;
$button-background-color: #e0e0e0;
$button-background-color-dark: #555555;
$incorrect-color: #e8000b;
$correct-color: #28a745;
$max-color: #ff0000;

.golfTrivia {
  padding: 2rem 1rem;
  background-color: $background-color;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 4px 20px $shadow-color;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 1rem;
  }

  &.dark-mode {
    background-color: $background-color-dark;
    box-shadow: 0 4px 20px $shadow-color-dark;
  }

  h1 {
    color: $primary-color;
    text-align: center;
    margin-bottom: 2rem;

    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  }
}

.questionSection {
  text-align: center;
  position: relative;

  h2 {
    color: $text-color;
    font-size: 1.5rem;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
      font-size: 1.25rem;
    }
  }

  h3 {
    color: $text-color;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;

    @media (max-width: 600px) {
      font-size: 1.1rem;
    }
  }

  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2rem;

    @media (max-width: 600px) {
      gap: 0.5rem;
      grid-template-columns: 1fr;
    }
  }

  .optionButton {
    background-color: $button-background-color;
    color: $text-color;
    padding: 1rem;
    border: 1px solid $border-color;
    border-radius: $button-radius;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;

    &:hover {
      background-color: lighten($background-color, 10%);
      transform: $hover-transform;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    &.correct {
      background-color: $correct-color;
      color: $white-color;
      border-color: $correct-color;
    }

    &.incorrect {
      background-color: $incorrect-color;
      color: $white-color;
      border-color: $incorrect-color;
      transform: none;
    }

    @media (max-width: 600px) {
      padding: 0.75rem;
      font-size: 0.875rem;
    }
  }

  .timer {
    font-size: 1rem;
    color: $subtitle-color;
    margin-top: 1rem;

    @media (max-width: 600px) {
      font-size: 0.875rem;
    }
  }
  .shotCounter {
    font-size: 1.25rem;
    color: $primary-color;
    margin-top: 2rem;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
  
  .shotTracker {
    display: flex;
    align-items: center;
    justify-content: center;  // Center the tracker horizontally
    gap: 0.8rem;  // Space between shot icons
    background-color: $primary-color;  // Navy background similar to PGA TOUR
    padding: 0.5rem 1rem;  // Padding for spacing
    border-radius: $container-radius;  // Rounded corners for the shot tracker
    box-shadow: 0px 2px 8px $shadow-color;  // Subtle shadow for depth
  
    .holeNumber {
      font-size: 1.8rem;
      color: $text-color-dark;  // White color for text on navy background
      font-weight: bold;
      margin-right: 1rem;  // Space between hole number and shots
    }
  
    .shotIcon {
      background-color: $primary-color;  // Navy background for shot circles
      color: $text-color-dark;  // White text for shot numbers
      border-radius: 50%;  // Circular shape
      width: 35px;  // Width of the circle
      height: 35px;  // Height of the circle
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      border: 2px solid $border-color;  // Light grey border
      margin-right: 0.5rem; // Space between shot icons
  
      &.currentShot {
        background-color: $secondary-color;  // Highlight current shot with orange
        color: $white-color;  // White text for current shot
        border-color: $secondary-color;  // Match border color with background
      }
    }
  }
  
}


.resultSection {
  text-align: center;

  h2 {
    color: $primary-color;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 600px) {
      font-size: 1.25rem;
    }
  }

  .shareButton {
    background-color: $primary-color;
    color: $white-color;
    padding: 1rem 2rem;
    border-radius: $button-radius;
    font-size: 1.1rem;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
      background-color: $secondary-color;
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 600px) {
      padding: 0.75rem 1.5rem;
      font-size: 0.875rem;
    }
  }
}

.scorecard {
  margin-top: 2rem;
  border: 1px solid $border-color;
  border-radius: $container-radius;
  padding: 1rem;
  background-color: $background-color;
  overflow-x: auto; // Enables horizontal scrolling
  white-space: nowrap;

  .headerRow,
  .parRow,
  .scoreRow {
    display: grid;
    grid-template-columns: 120px repeat(31, minmax(40px, 1fr)) 120px; // Ensures alignment with a fixed label column and scrollable score columns
    text-align: center;
    margin-bottom: 1rem;
    white-space: nowrap; // Prevents wrapping of grid items
  }

  .label {
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
    grid-column: span 1;
  }

  .holeNumber, .parValue, .scoreValue {
    padding: 0.5rem;
    white-space: nowrap;
    border-right: 1px solid $border-color; // Adds a divider between columns
  }

  .parValue {
    font-weight: bold;
    color: $primary-color;
  }

  .scoreValue {
    font-size: 1.25rem;
    position: relative;

    &.eagle {
      border: 2px solid $correct-color;
      border-radius: 50%;
      padding: 2px;
    }

    &.eagle::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 2px solid $correct-color;
      border-radius: 50%;
    }

    &.birdie {
      border: 2px solid $primary-color;
      border-radius: 50%;
    }

    &.par {
      color: $text-color;
    }

    &.bogey {
      border: 2px solid $incorrect-color;
    }

    &.doubleBogey {
      border: 2px solid $incorrect-color;
      border-width: 2px;
      padding: 3px;
    }

    &.doubleBogey::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 2px solid $incorrect-color;
    }

    &.max {
      color: $max-color;
      font-weight: bold;
      font-size: 1.5rem;
      border: 2px solid $max-color;  // Add a border for the "Max" score
      border-radius: 50%;  // Make it circular like the other scores
      padding: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border: 2px dashed $max-color;
        border-radius: 50%;
      }
    }

    &.missedCut {
      background-color: $incorrect-color;
      color: $white-color;
      padding: 0.5rem;
      border-radius: $button-radius;
    }
  }
}

.shotTracker {
    display: flex;
    align-items: center;
    justify-content: center;  // Center the tracker horizontally
    gap: 0.8rem;  // Space between shot icons
    background-color: $primary-color;  // Navy background similar to PGA TOUR
    padding: 0.5rem 1rem;  // Padding for spacing
    border-radius: $container-radius;  // Rounded corners for the shot tracker
    box-shadow: 0px 2px 8px $shadow-color;  // Subtle shadow for depth
  
    .holeNumber {
      font-size: 1.8rem;
      color: $text-color-dark;  // White color for text on navy background
      font-weight: bold;
      margin-right: 1rem;  // Space between hole number and shots
    }
  
    .shotIcon {
      background-color: $primary-color;  // Navy background for shot circles
      color: $text-color-dark;  // White text for shot numbers
      border-radius: 50%;  // Circular shape
      width: 35px;  // Width of the circle
      height: 35px;  // Height of the circle
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      border: 2px solid $border-color;  // Light grey border
      margin-right: 0.5rem; // Space between shot icons
  
      &.currentShot {
        background-color: $secondary-color;  // Highlight current shot with orange
        color: $white-color;  // White text for current shot
        border-color: $secondary-color;  // Match border color with background
      }
    }
  }
  .shotLabel {
    font-size: 1.2rem;
    color: $white-color;  // White color for shot label text
    font-weight: bold;
    margin-right: 1rem;  // Space between shot label and shots
  }