:root {
    --primary-color: #7ACF8B; /* Airbnb's iconic pink for primary actions */
    --secondary-color: #00A699; /* A soothing green for secondary actions */
    --background-color: #F7F7F7; /* Light grey background for minimalism */
    --text-color: #484848; /* Dark grey for readability */
    --font-family: 'Circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

    /* Dark mode variables */
    --primary-color-dark: #7ACF8B;
    --background-color-dark: #222;
    --text-color-dark: #fff;

    /* Interactive elements */
    --button-border-radius: 8px;
    --button-padding: 12px 24px;
    --button-font-weight: bold;

    /* Box Shadows */
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: var(--primary-color);
    text-decoration: none; /* Encourage clean design */
}

a:hover {
    text-decoration: underline;
}

/* Utility classes for margins & padding */
.m-0 { margin: 0; }
.p-0 { padding: 0; }
.mx-auto { margin-left: auto; margin-right: auto; }
.text-center { text-align: center; }
.game-header {
    background-color: #fff;
    box-shadow: var(--box-shadow);
    position: sticky;
    top: 0;
    z-index: 100;
}
  a {
    color: #FF5A5F;
    text-decoration: none;
  }

  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 1140px;
    margin: 0 auto;
  }
  
  .brand-name {
    font-size: 20px;
    font-weight: bold;
    color: #FF5A5F;
  }
  
  .header-content .brand-name {
    font-size: 20px;
    font-weight: bold;
    color: #FF5A5F;
}
/* General styles remain unchanged */

/* Airbnb-like variables */
:root {
    --primary-color: #FF385C; /* Airbnb red for primary elements */
    --menu-background-color: #fff; /* White background for the dropdown */
    --menu-text-color: #484848; /* Almost black for text */
    --menu-hover-background-color: #f7f7f7; /* Light gray for hover background */
}.navigation-menu {
    display: none;
    position: absolute; /* or 'fixed' if you want it to be placed relative to the viewport */
    top: 60px; /* Adjust this value so it appears right below the header */
    left: 0;
    right: 0;
    margin: auto; /* This centers the menu */
    width: 90%; /* Or any other value that fits well */
    z-index: 9999; /* Adjust if necessary to be higher than the ads */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
}

.navigation-menu.open {
    display: block; /* or 'flex' if you use flexbox for layout */
}

.navigation-menu ul {
    list-style-type: none;
    padding: 0; /* Corrected, you can adjust values as needed */
    margin-left: 170px; /* Corrected, you can adjust values as needed */

}

.navigation-menu li {
    text-align: left; /* Align text to the left */
    display: block; /* Make the whole row selectable */
    
}

.navigation-menu li a {
    display: block; /* Ensures the link fills the entire list item */
    padding: 15px 20px; /* Adjust padding as needed, original was quite large */
    color: var(--menu-text-color);
    text-decoration: none;
}

.navigation-menu li a:hover {
    background-color: var(--menu-hover-background-color);
}

/* The rest of your provided styles would go here */



/* Responsive adjustments */
@media (min-width: 768px) {
    .navigation-menu {
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: none;
        box-shadow: none;
        top: auto;
        right: auto;
    }

    .nav-link {
        padding: 10px 15px;
        color: var(--primary-color); /* Airbnb red for desktop */
    }

    .nav-link:hover {
        background-color: transparent;
        text-decoration: underline;
    }
}

/* Form and Search */
.search-container {
    margin: 10px 0;
}

.search-input {
    width: 100%;
    padding: 15px 20px;
    border-radius: 30px;
    border: 1px solid #DDDDDD;
    font-size: 16px;
}

.search-input:focus {
    border-color: #FF5A5F;
    outline: none;
}

button {
    padding: 12px 24px; /* Uniform padding */
    font-size: 1rem; /* Standard font size */
    font-weight: var(--button-font-weight); /* Weight from your CSS variables */
    border: none; /* No border */
    border-radius: var(--button-border-radius); /* Rounded edges from your CSS variables */
    cursor: pointer; /* Cursor for clickable items */
    transition: background-color 0.3s; /* Smooth background color transition */
  }
  
  button:hover {
    background-color: var(--primary-color-dark); /* Darker color on hover */
  }

/* Card and List Styles */
.movie-card,
.guess-history,
.guess-row,
.feedback-item {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}
/* Table Styles for Guess History and Hints */
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #EEEEEE;
}

th {
    background-color: #FAFAFA;
}

/* Scrollable Table Container for Horizontal Scrolling */
.hint-table-container,
.guess-history {
    overflow-x: auto;
}

/* Reveal Hint Styles */
/* Reveal Hint Styles */
.reveal-hint {
    font-size: 14px; /* Small font size */
    color: #007a87; /* Turquoise color */
    background-color: #f0f0f0; /* Light gray background color */
    display: inline-block; /* Display as inline block to wrap content */
    padding: 6px 12px; /* Add padding for button appearance */
    border-radius: 20px; /* Increased border-radius for more rounded edges */
    cursor: pointer; /* Cursor pointer */
}


.hint-cost {
    font-size: 12px; /* Smaller font size */
    color: #888; /* Light gray color */
    margin-left: 5px; /* Add spacing between the text and cost */
}

/* Modal Styles */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
    width: 80%;
    max-width: 500px;
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
    .game-container,
    .modal-content {
        padding: 10px;
    }

    .nav-link {
        margin: 5px 0;
    }
}

@media (max-width: 480px) {
    .brand-name {
        font-size: 18px;
    }

    .search-input,
    button {
        padding: 12px 15px;
        font-size: 14px;
    }

    .game-header {
        padding: 15px 0;
    }

    .modal-content {
        width: 90%;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #eee;
}

th {
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Highlight row on hover for better interactivity */
tr:hover {
    background-color: var(--background-color);
}/* Search results list */
.search-results {
    width: 100%;
    margin-bottom: 30px;
    max-height: 400px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
}

/* Individual search result item */
.search-result-item {
    padding: 20px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;
}

.search-result-item:hover {
    background-color: #f7f7f7;
}

.search-result-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-color); /* Ensure color contrasts well on both light and dark backgrounds */
    margin-bottom: 5px;
}

.search-result-description {
    font-size: 16px;
    color: var(--text-color); /* Use CSS variable for easy dark mode adjustment */
}

.search-result-meta {
    font-size: 14px;
    color: #999; /* Lighter color for meta info, adjust for dark mode if necessary */
}

/* Dark Mode Adjustments */
.dark-mode .search-result-item {
    color: var(--text-color-dark); /* Ensure text is readable in dark mode */
    background-color: var(--background-color-dark); /* Adjust background for contrast */
    border-bottom-color: rgba(255, 255, 255, 0.1); /* Lighter border for separation */
}

.dark-mode .search-result-item:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Lighter on hover for interactive feedback */
}

.dark-mode .search-result-title,
.dark-mode .search-result-description,
.dark-mode .search-result-meta {
    color: var(--text-color-dark); /* Ensure all text is readable in dark mode */
}


.correct {
    color: green;
}

.incorrect {
    color: red;
}

/* Dark mode styles */
.dark-mode {
    --primary-color: var(--primary-color-dark);
    --background-color: var(--background-color-dark);
    --text-color: var(--text-color-dark);
}

/* Header styles for dark mode */
.dark-mode .game-header {
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Adjusted Navigation Menu styles for dark mode */
.dark-mode .navigation-menu {
    background-color: var(--background-color);
}

/* Adjusted Main Container styles for dark mode */
.dark-mode .game-container {
    background-color: var(--background-color);
    color: var(--text-color);
}
/* Header Styles */
.game-header {
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000; /* Existing z-index */
    padding: 20px 0;
}

/* Adjusted Navigation Menu Styles */
.navigation-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #FFFFFF;
    top: 60px;
    right: 0;
    width: 100%;
    box-shadow: var(--box-shadow);
    z-index: 1010; /* Increased z-index */
}

/* Adjusted Main Container Styles */
.game-container {
    padding-top: 100px; /* Increased top padding */
    padding-bottom: 200px;
    max-width: 900px;
    margin: auto;
}

  
  .header-content .brand-name {
    color: #FF385C;
    margin: 0;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 24px;
    color: #484848;
  }
  


/* Hamburger Menu Icon Styling - Always visible */
.icon-container .menu-icon {
    cursor: pointer;
    display: block; /* This ensures the icon is always visible */
}

/* No need to hide the icon on larger screens if it should be accessible at all times */
/* Remove or comment out the previous media query that hides the icon */
/*
@media (min-width: 768px) {
    .icon-container .menu-icon {
      display: none; // This line would be removed or commented out
    }
}
*/

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    z-index: 1050; /* Ensure it's above most other content */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal Content */
.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px; /* Rounded corners like Airbnb's design */
    width: 90%;
    max-width: 500px; /* Adjust based on your preference */
    box-shadow: var(--box-shadow);
    z-index: 1051; /* Above the overlay */
}

/* Modal Header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Space between header and body */
}

.modal-title {
    margin: 0;
    color: var(--primary-color);
    font-weight: bold;
}

/* For the year selection buttons within the modal */
.year-selection-button {
    display: inline-block;
    margin: 5px;
    padding: 10px 15px;
    background-color: var(--secondary-color);
    color: #fff;
    border-radius: var(--button-border-radius);
    cursor: pointer;
    transition: background-color 0.3s;
}

.year-selection-button:hover {
    background-color: darken(var(--secondary-color), 10%);
}
.modal-backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop for focus */
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 12px; /* Rounded corners like Airbnb */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    width: 90%; /* Responsive width */
    max-width: 600px; /* Max width to avoid too large modal on big screens */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Clean, modern font */
  }
  
  .modal-header {
    margin-bottom: 20px; /* Space between header and content */
  }
  
  .modal-title {
    font-size: 24px; /* Prominent, readable title */
    font-weight: bold;
  }
  
  .modal-body-scroll {
    max-height: 400px; /* Adjust based on your preference */
    overflow-y: auto; /* Enables vertical scrolling */
    margin-bottom: 20px; /* Space between content and footer */
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
  }
  
  .modal-footer .close-modal-button, .modal-footer .see-all-button {
    background-color: #FF385C; /* Airbnb's brand color for call to action */
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    margin-left: 10px; /* Space between buttons */
    border-radius: 8px; /* Rounded corners for buttons */
    cursor: pointer;
    font-weight: bold;
  }
  
  .modal-footer .close-modal-button:hover, .modal-footer .see-all-button:hover {
    background-color: #f53e6a; /* Slightly darker on hover for interaction feedback */
  }
  

  /* Apply dark mode styles to specific elements */
.dark-mode .search-input,
.dark-mode table th,
.dark-mode table td {
    color: var(--text-color-dark); /* White text color in dark mode */
    /* Add any other dark mode specific styles here */
}

/* Ensure the search input has a clear visual in dark mode */
.dark-mode .search-input {
    background-color: var(--background-color-dark); /* Dark background for the input */
    border-color: var(--primary-color-dark); /* Adjust the border color if necessary */
}

/* Adjust the placeholder color for search input in dark mode */
.dark-mode .search-input::placeholder {
    color: var(--text-color-dark); /* Lighter text color for placeholder in dark mode */
    opacity: 0.7; /* Adjust opacity to make it clear it's a placeholder */
}



/* Ensure links within the navigation menu have the correct color in dark mode */
.dark-mode .nav-link {
    color: var(--text-color-dark); /* This will ensure links are visible in dark mode */
}

/* Update hover effects for links in dark mode */
.dark-mode a:hover {
    color: var(--text-color-dark); /* Ensure hover color is visible in dark mode */
    text-decoration: underline; /* Optional: underline to show it's interactive */
}
body {
    transition: background-color 0.3s, color 0.3s;
}

/* Apply transitions to other elements as needed */
.search-input,
button,
.nav-link,
.movie-card,
.guess-history {
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}


/* Adjust the movie titles in the table for dark mode */
.dark-mode .guess-history th,
.dark-mode .guess-history td {
    color: var(--text-color-dark); /* This will be white or any color set for dark mode */
}

/* If your movie titles have a specific class, target them like this */
.dark-mode .movie-title {
    color: var(--text-color-dark);
}

/* You may also want to adjust the background of the search input field for better visibility */
.dark-mode .search-input {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
    border: 1px solid #555; /* Assuming a lighter border for input fields */
}

/* Same goes for the placeholder text */
.dark-mode .search-input::placeholder {
    color: rgba(255, 255, 255, 0.7); /* Lighter white for placeholders */
}

/* Ensure the correct and incorrect classes are also adjusted for dark mode */
.dark-mode .correct {
    color: #38FF58; /* A brighter shade of green for correct */
}

.dark-mode .incorrect {
    color: #FF3838; /* A brighter shade of red for incorrect */
}
.dark-mode {
    --text-color: #ffffff; /* This will set the text color to white in dark mode */
    --secondary-text-color: #cccccc; /* A lighter grey for less prominent text */
    --placeholder-text-color: #777777; /* A mid-grey for placeholder text */
    --table-header-bg: #333333; /* A dark grey for table headers */
}

.dark-mode .search-input::placeholder {
    color: var(--placeholder-text-color); /* Use the lighter grey for placeholder text */
}

.dark-mode .movie-title,
.dark-mode th {
    color: var(--text-color); /* Use the white color for titles and table headers */
}


.dark-mode td {
    color: var(--secondary-text-color); /* Use the lighter grey for less prominent text */
}

.dark-mode th {
    background-color: var(--table-header-bg); /* Use the dark grey for table headers */
}

/* Ensuring table rows are visible in dark mode */
.dark-mode tr {
    background-color: var(--background-color-dark); /* Rows will take the dark background color */
}

/* Adjusting the colors for correct and incorrect feedback */
.dark-mode .correct {
    color: #0F0; /* Bright green color for correct feedback */
}

.dark-mode .incorrect {
    color: #F00; /* Bright red color for incorrect feedback */
}
/* Adjust the background and text color of each search result item for dark mode */
.dark-mode .movie-card {
    background-color: var(--background-color-dark); /* Dark background for each item */
    color: var(--text-color-dark); /* Lighter text color for readability */
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Lighter border for separation */
}

/* Adjust the color of the release year span for dark mode */
.dark-mode  .movie-card .release-year {
    color: var(--text-color-dark); /* Lighter text color for readability */
}


/* Ensure that the search result items are visible on hover */
.dark-mode  .movie-card:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Slightly lighter background on hover */
}


/* Adjust the movie titles and other texts in the guess history table for dark mode */
.dark-mode .guess-history th,
.dark-mode .guess-history td {
    background-color: var(--background-color-dark); /* Dark background for table cells */
    color: var(--text-color-dark); /* Lighter text for readability */
}

/* For hovered rows in the table */
.dark-mode .guess-history tr:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Slightly lighter on hover for interactivity */
}

/* Update the search input field for better visibility in dark mode */
.dark-mode .search-input {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
    border-color: rgba(255, 255, 255, 0.5); /* Lighter border for visibility */
}

  
/* For buttons and links in the guess history table */
.dark-mode .guess-history 
.dark-mode .guess-history .reset-button {
    background-color: var(--primary-color-dark); /* Darker background for buttons */
    color: var(--text-color-dark); /* Lighter text for buttons */
}

/* Adjust hover effect for buttons */
.dark-mode .guess-history ,
.dark-mode .guess-history .reset-button:hover {
    background-color: lighten(var(--primary-color-dark), 10%); /* Lighter on hover */
}

/* Update hover effects for links in dark mode */
.dark-mode a:hover {
    color: lighten(var(--text-color-dark), 20%); /* Lighter hover color for links */
}
/* Dark mode styles */
.dark-mode .modal-overlay,
.dark-mode .modal-content,
.dark-mode .modal-header,
.dark-mode .modal-footer .close-modal-button,
.dark-mode .modal-footer .see-all-button {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
    /* other dark mode styles as necessary */
}

.dark-mode .modal-content {
    border-color: var(--text-color-dark); /* if you want borders to be visible */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .modal-content {
        width: 95%; /* Make modals slightly narrower on smaller screens */
        /* Adjust other modal styles as necessary for smaller screens */
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 100%; /* Modals can take full width on very small screens */
        /* Adjust other modal styles as necessary for very small screens */
    }

    .modal-header .modal-title,
    .modal-footer .close-modal-button,
    .modal-footer .see-all-button {
        /* Adjust font sizes and paddings for very small screens */
        font-size: smaller;
        padding: 8px 16px;
    }
}

/* Ensure smooth transitions for dark mode */
body.dark-mode .transition,
.dark-mode .modal-overlay,
.dark-mode .modal-content,
.dark-mode .modal-header,
.dark-mode .modal-footer .close-modal-button,
.dark-mode .modal-footer .see-all-button {
    transition: background-color 0.3s ease, color 0.3s ease;
}
.mode-button {
    padding: 10px;
    margin: 5px;
    border: 1px solid gray;
    background-color: white;
    cursor: pointer;
  }
  
  .mode-button.selected {
    background-color: navy;
    color: white;
    border-color: navy;
  }
  /* The switch - the box around the slider */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .mode-switch-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .mode-switch-label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .mode-switch-label:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .mode-switch-checkbox:checked + .mode-switch-label {
    background-color: #2196F3;
  }
  
  .mode-switch-checkbox:checked + .mode-switch-label:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .mode-switch-label {
    border-radius: 34px;
  }
  
  .mode-switch-label:before {
    border-radius: 50%;
  }
  .game-mode-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Adjust the space between the labels and the switch */
  }
  
  .switch-label {
    font-weight: bold;
    color: #666;
  }
  
  /* Existing styles for .toggle-switch and related elements */
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    /* other styles */
}.dark-mode .game-page {
    --background-color: var(--background-color-dark);
    --text-color: var(--text-color-dark);
    background-color: var(--background-color);
    color: var(--text-color);
    /* Add other dark mode styles here */
  }
  
/* Define dark mode styles */
.dark-mode .game-container {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
    /* Add other dark mode styles here */
  }
 
  
  /* For example, table rows in dark mode */
  .dark-mode .game-container table tr {
    background-color: var(--background-color-dark); /* Or a slightly different shade if you want */
  }
  
  /* Modals in dark mode */
  .dark-mode .modal-content {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
  }
  
  /* Text inputs in dark mode */
  .dark-mode .game-container .search-input {
    background-color: #333; /* Darker shade for input background */
    color: var(--text-color-dark);
    border-color: #444; /* Darker border color */
  }
  
  /* ... */
  .dark-mode .game-page {
    background-color: var(--background-color-dark); /* This will set the background for the entire game page */
    color: var(--text-color-dark);
    /* Set other styles as needed */
  }
  /* Apply dark mode styles to game-page and all of its children */
.dark-mode .game-page {
    background-color: var(--background-color-dark); /* This will set the background for the entire game page */
    color: var(--text-color-dark);
    /* Set other styles as needed */
  }
  

  /* You might also need to set the background for body to ensure the entire page is covered */
  .dark-mode body {
    background-color: var(--background-color-dark);
  }
  /* Ensure the correct and incorrect classes are also adjusted for dark mode */
.dark-mode .correct {
    color: #38FF58; /* A brighter shade of green for correct */
}

.dark-mode .incorrect {
    color: #FF3838; /* A brighter shade of red for incorrect */
}
/* Adjust the movie titles in the table for dark mode */
.dark-mode .guess-history th,
.dark-mode .guess-history td {
    color: var(--text-color-dark); /* This will be white or any color set for dark mode */
}

  .stats-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .stat-value {
    font-size: 24px; /* large font size for stats */
  }
  .stat-label {
    font-size: 14px; /* smaller font size for labels */
  }
  .answer-display {
    text-align: center;
    margin: 20px 0;
  }
  .modal-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  .promotional-footer {
    text-align: center;
    margin-top: 20px;
    font-style: italic;
  }
  .hint-toggle-button, .give-up-button {
    padding: 8px 16px; /* Reduced padding for less vertical space */
    font-size: 1rem; /* Adjust font size as needed for modern look */
    border: none; /* Remove border for a cleaner look */
    background-color: var(--primary-color); /* Use the primary color variable */
    color: white; /* White text */
    text-align: center;
    text-decoration: none;
    border-radius: var(--button-border-radius); /* Rounded edges */
    box-shadow: var(--box-shadow); /* Subtle shadow for depth */
    transition: background-color 0.2s ease-in-out; /* Smooth transition for hover effect */
    cursor: pointer; /* Indicates the button is clickable */
    display: inline-block; /* Keeps the button inline */
    margin-right: 10px; /* Adds margin to the right */
    min-width: 120px; /* Adjust as necessary to prevent text wrapping */
    white-space: nowrap; /* Prevents the text from wrapping */
}
.hint-toggle-button {
    /* Existing styles */
    background-color: var(--secondary-color); /* Assuming you have a secondary color variable */
    margin-bottom: 0; /* Removes margin from the bottom */
}

.give-up-button {
    /* Existing styles */
    background-color: transparent; /* Make the background transparent */
    color: var(--primary-color); /* Change text color to primary color */
    border: 1px solid var(--primary-color); /* Add a thin border */
}

.give-up-button:hover {
    background-color: rgba(255, 255, 255, 0.05); /* Add a subtle background color on hover */
    color: var(--primary-color-dark); /* Darken the text color on hover */
    border-color: var(--primary-color-dark); /* Darken the border color on hover */
}

.dark-mode .give-up-button {
    /* Dark mode styles */
    color: var(--primary-color-light); /* Change text color to a lighter shade */
    border-color: var(--primary-color-light); /* Change border color to a lighter shade */
}

.dark-mode .give-up-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Add a subtle background color on hover in dark mode */
    color: var(--primary-color); /* Change text color back to primary color on hover */
    border-color: var(--primary-color); /* Change border color back to primary color on hover */
}

.hint-toggle-button:hover {
    background-color: var(--primary-color-dark); /* Slightly darker color on hover */
}

.top-actions-container {
    display: flex; /* This will layout the children (buttons) in a row */
    justify-content: center; /* This will center the buttons in the container */
    align-items: center; /* This will vertically align the buttons in the middle */
    gap: 10px; /* This adds space between the buttons */
    width: 100%; /* Ensure the container takes full width of its parent */
    max-width: 600px; /* Adjust this value based on your layout */

}

  
  .score-hint-container {
    display: flex;
    flex-direction: column; /* Stack scoreboard vertically */
    align-items: center; /* Center horizontally */
    gap: 10px; /* Space between scoreboard and whatever might be below */
  }
  

.scoreboard {
    background-color: var(--background-color); /* Light background */
    color: var(--text-color); /* Dark text */
    padding: 10px 10px; /* Padding for inner spacing */
    border-radius: var(--button-border-radius); /* Rounded corners */
    box-shadow: var(--box-shadow); /* Soft shadow */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    gap: 20px; /* Space between items */
}

.scoreboard p {
    margin: 0; /* Reset margin */
    font-size: 14px; /* Font size for readability */
    color: var(--text-color); /* Text color for consistency */
}

.scoreboard p:first-child {
    font-weight: var(--button-font-weight); /* Bold for emphasis */
    color: var(--primary-color); /* Primary color for importance */
}


  /* Base styling for the EndGameModal backdrop */
.modal-backdrop.end-game-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Styling for the modal content with light/dark mode support */
  .end-game-modal .modal-content {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    border-radius: var(--button-border-radius);
    box-shadow: var(--box-shadow);
    width: 90%;
    max-width: 500px;
    font-family: var(--font-family);
  }
  
  /* Header with a little more style */
  .end-game-modal .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .end-game-modal .modal-header h2 {
    margin: 0;
    font-size: 24px;
  }
  
  /* Style for the close button */
  .end-game-modal .close-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* Body of the modal for better text visibility */
  .end-game-modal .modal-body {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  /* Footer with buttons styled */
  .end-game-modal .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  
  .end-game-modal .modal-footer button {
    padding: var(--button-padding);
    border: none;
    border-radius: var(--button-border-radius);
    cursor: pointer;
    font-weight: var(--button-font-weight);
    transition: background-color 0.2s;
  }
  
  /* Additional styling for specific buttons using Airbnb color scheme */
  .end-game-modal .modal-footer button:first-child {
    background-color: var(--primary-color);
    color: #fff;
  }
  
  .end-game-modal .modal-footer button:first-child:hover {
    background-color: var(--primary-color-dark);
  }
  
  .end-game-modal .modal-footer button:nth-child(2) {
    background-color: var(--secondary-color);
    color: #fff;
  }
  
  .end-game-modal .modal-footer button:nth-child(2):hover {
    background-color: darken(var(--secondary-color), 10%);
  }
  
  .end-game-modal .modal-footer button:last-child {
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--text-color);
  }
  
  .end-game-modal .modal-footer button:last-child:hover {
    background-color: var(--text-color);
    color: #fff;
  }
  /* Dark mode specific styles for modals */
.dark-mode .modal-content {
  background-color: #333; /* Dark background for better visibility */
  color: #fff; /* Light text color for contrast */
  border-color: #444; /* Optionally, adjust the border color if needed */
}

.dark-mode .modal-header,
.dark-mode .modal-body,
.dark-mode .modal-footer {
  color: #fff; /* Ensure all text is readable */
}

/* Adjust button styles in the modal for dark mode */
.dark-mode .modal-footer .close-modal-button,
.dark-mode .modal-footer .see-all-button {
  background-color: #555; /* Darker background for buttons */
  color: #fff; /* White text for buttons */
}

.dark-mode .modal-footer .close-modal-button:hover,
.dark-mode .modal-footer .see-all-button:hover {
  background-color: #666; /* Lighten on hover for interactivity */
}

/* Adjust links or other interactive elements within the modal */
.dark-mode .modal-content a {
  color: #aad; /* Light blue for links to stand out */
  text-decoration: underline; /* Ensure links are identifiable */
}
/* Base styles for navigation */
.navigation-menu {
  background-color: white;
  position: fixed;
  top: 60px; /* adjusted for header height */
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateY(-100%); /* hide by default */
  z-index: 1010;
}

/* Adjust navigation for mobile screens */
@media (max-width: 768px) {
  .navigation-menu.open {
      transform: translateY(0); /* show menu */
  }
}

/* Improve touch area and visual spacing for menu items */
.navigation-menu li a {
  padding: 12px 20px; /* larger touch area */
  display: block; /* improves tap-ability */
  color: #333; /* darker for better readability */
}

/* Hover and focus styles for better navigation feedback */
.navigation-menu li a:hover,
.navigation-menu li a:focus {
  background-color: #f7f7f7; /* light background on hover/focus */
  color: #00A699; /* highlight color for interactivity */
}

/* Dark mode adjustments for navigation */
.dark-mode .navigation-menu {
  background-color: #222; /* dark background for dark mode */
  color: #fff; /* white text for readability */
}

.dark-mode .navigation-menu li a {
  color: #fff; /* white text links in dark mode */
}

.dark-mode .navigation-menu li a:hover,
.dark-mode .navigation-menu li a:focus {
  background-color: #333; /* darker background on hover/focus in dark mode */
}
/* Dark Mode Button Adjustments */
.dark-mode .button {
  background-color: #4CAF50; /* A darker shade of green for better visibility */
  color: #000000; /* Black text for higher contrast */
  border: 1px solid #4CAF50; /* Consistent border color with the background */
}

.dark-mode .button:hover {
  background-color: #367C39; /* An even darker shade when hovered for a subtle effect */
  color: #ffffff; /* White text on hover for better readability */
}
