/* Variables */
$primary-color: #0e0e0e;
$secondary-color: #333333;
$background-color: #f4f4f4;
$button-color: #4682B4;

/* Dark Mode Variables */
$primary-color-dark: #2C2C2E;
$secondary-color-dark: #3A3A3C;
$background-color-dark: #1C1C1E;
$button-color-dark: #4682B4;
$text-color-dark: #F0F0F0;

/* Light Mode Variables */
$text-color-light: #0e0e0e;

.needLoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 20px;
  text-align: center;
  overflow-y: auto;

  &.darkMode {
    background-color: $background-color-dark;
    color: $text-color-dark;
  }

  &.lightMode {
    background-color: $background-color;
    color: $text-color-light;
  }
}

.contentWrapper {
  max-width: 600px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.needLoginTitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: $button-color;
}

.needLoginText {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.needLoginSubtext {
  font-size: 1rem;
  margin-bottom: 20px;
}

.signInButtonWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .userButton {
    background-color: $button-color;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
      background-color: darken($button-color, 10%);
      transform: translateY(-2px);
    }
  }
}

.imageWrapper {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
}

.embedImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
