$primary-color: #003A70;
$secondary-color: #E8000B;
$background-color: #f5f5f5;
$background-color-dark: #121212;
$white-color: #ffffff;
$text-color: #202124;
$text-color-dark: #e0e0e0;
$subtitle-color: #5f6368;
$subtitle-color-dark: #8d8d8d;
$border-color: #dadce0;
$border-color-dark: #424242;
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-dark: rgba(0, 0, 0, 0.7);
$hover-transform: translateY(-2px);
$button-radius: 8px;
$container-radius: 16px;
$button-background-color: #f2f2f2;
$button-background-color-dark: #333333;

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.modalContainer {
  background: $white-color;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  color: $text-color;

  body.dark-mode & {
    background: $background-color-dark;
    color: $text-color-dark;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  background: $white-color;
  z-index: 1;

  body.dark-mode & {
    background: $background-color-dark;
    border-bottom: 1px solid $border-color-dark;
  }
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: $text-color;

  body.dark-mode & {
    color: $text-color-dark;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.tab {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &:focus {
    outline: none;
  }
}

.tab.active {
  border-bottom: 2px solid $primary-color;
  color: $primary-color;
}

.tabContent {
  padding: 16px;
}

.tabPanel {
  padding: 16px;
  overflow-y: auto;
}

.tabPanel h3 {
  margin-top: 0;
}

.tabPanel ul {
  list-style: disc;
  padding-left: 20px;
}

.tabPanel ul ul {
  list-style: circle;
}

.tabPanel p, .tabPanel ul, .tabPanel li {
  margin: 8px 0;
}

@media (max-width: 600px) {
  .modalHeader {
    flex-wrap: nowrap;
  }

  .tabs {
    justify-content: flex-start;
    flex-grow: 1;
  }

  .tab {
    flex-grow: 1;
    text-align: center;
  }
}
