/* PressRelease.css */
.press-release-container {
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 800px;
    margin: 0 auto;
}

h1, h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 20px;
    line-height: 1.5; /* improves readability */
}

a {
    color: #007BFF; /* or another accent color */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Mobile-first approach */
@media screen and (max-width: 600px) {
    .press-release-container {
        padding: 15px;
    }

    h1 {
        font-size: 24px; /* decrease h1 font size for mobile */
    }

    h2 {
        font-size: 20px; /* decrease h2 font size for mobile */
    }
}
/* Add the following styles */

.banner {
    display: flex;
    align-items: center;
    background-color: #f5f5f5; /* choose a suitable background color */
    padding: 10px;
    margin-bottom: 20px;
}

.banner a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit; /* ensures the text doesn't get the default blue link color */
}

.banner img {
    width: 50px; /* adjust based on the size of your logo */
    height: 50px; /* adjust based on the size of your logo */
    margin-right: 10px;
}
/* Styles for play grids link */

.play-grid-link {
    text-align: center;
    margin-top: 20px;
}

.play-grid-link a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333; /* Adjust as needed */
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.play-grid-link a:hover {
    background-color: #555; /* Adjust as needed */
}

/* Previous styles from the last instruction */

/* Styles for additional links */

.additional-links {
    margin-top: 20px;
    text-align: center;
}

.twitter-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #1DA1F2; /* Twitter blue */
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.twitter-link:hover {
    background-color: #0c83d5; /* Adjusted Twitter blue for hover */
}

/* Styles for newsletter embed */

.newsletter-embed {
    margin-top: 20px;
    text-align: center;
}
/* .navbar {
    display: flex;
    align-items: center;
    padding: 10px;
} */


.navbar-text {
    margin-left: 10px;
    font-weight: bold;
    font-size: 20px;
}

/* ... Rest of your CSS remains unchanged ... */
