/* Variables */
$primary-color: #0e0e0e;
$secondary-color: #333333;
$background-color: #f4f4f4;
$button-color: #4682B4;

/* Dark Mode Variables */
$primary-color-dark: #2C2C2E;
$secondary-color-dark: #3A3A3C;
$background-color-dark: #1C1C1E;
$button-color-dark: #4682B4;
$text-color-dark: #F0F0F0;

/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

/* Page Styles */
.sportsPage {
  &.light-mode {
    background-color: $background-color;
    color: $primary-color;
  }

  &.dark-mode {
    background-color: $background-color-dark;
    color: $text-color-dark;
  }
}

.pageContentWrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.mainContent {
  width: 100%;
  max-width: 1200px;
}/* Add this CSS to your styles *//* Add this CSS to your styles */
.announcementBanner {
  background: linear-gradient(135deg, #4b6cb7 0%, #182848 100%); /* Softer gradient */
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 1.2rem;
  margin: 20px auto; /* Add top margin for spacing */
  border-radius: 10px;
  max-width: 800px; /* Control the width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif; /* Use a modern font */
}

.announcementText {
  margin-bottom: 15px;
}

.announcementText span {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 20px;
  background: #fff;
  color: #4b6cb7;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.announcementPlayButton {
  background-color: #28a745; /* More neutral green */
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 10px; /* Increase border radius */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif; /* Use a modern font */

  &:hover {
    background-color: #218838; /* Slightly darker green for hover */
    transform: translateY(-2px);
  }
}
/* Header */
.header {
  background-color: var(--primary-color);
  padding: 1rem 0;
  text-align: center;
}

.logo {
  font-size: 2rem;
  color: #fff;
}

/* Navigation */
.app-header {
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.navbar-title {
  font-weight: bold;
}

.nav-item {
  margin-right: 1rem;
  color: inherit;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.sportsPicker {
  background-color: (--secondary-color);
  padding: 2rem;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 2rem auto;
  position: relative;
}

.pickerTitle {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

/* Styles for the rest of the elements */
.sportPickerItem {
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  transition: background-color 0.3s, box-shadow 0.2s, transform 0.2s;
  text-align: center;
  min-width: 100px;
  font-weight: bold;

  &:hover,
  &:focus {
    outline: none;
    transform: translateY(-2px);
  }

  &:focus {
    box-shadow: 0 0 0 3px $button-color;
  }
}

.sportEmoji {
  margin-right: 8px;
  font-size: 1.5rem;
}

.showMoreButton {
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
  font-weight: bold;
}

/* Games Container */
.gamesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1px;
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: 20px;
}

.gameCard {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  max-width: 250px;
  align-items: center;
  margin-bottom: 20px;
}

.gameCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gameIcon {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.gameTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.gameDescription {
  font-size: 16px;
  margin-bottom: 1rem;
}

.gameActions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gameButton {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.gameButton:hover {
  background-color: #f0f0f0;
}

/* Sports Container */
.sportsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 0 auto;
}

/* SportCard Styles */
.sportCard {
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sportIconContainer {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sportIcon {
  width: 80%;
  height: auto;
}

.sportTitle {
  font-size: 1.5rem;
  margin: 10px 0;
}

.gameModes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.playButton {
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: darken($button-color, 10%);
  }
}

.sportContent {
  text-align: center;
}

.sportEmoji {
  font-size: 2.5rem;
}

.sportTitle {
  margin-top: 1rem;
  font-size: 1.8rem;
}

.gameModes {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

/* Footer */
.footer {
  background-color: var(--primary-color);
  padding: 2rem 0;
  text-align: center;
  margin-top: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1.4rem;
  color: #fff;
}

/* App Container */
.app-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Splash Section */
.splash-section {
  position: relative;
  overflow: hidden;
}

.splash-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.splash-heading {
  font-weight: bold;
  font-size: 2.5rem;
}

.splash-subheading {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 10px;
}

.splash-stat {
  background-color: #a2e5b2;
  color: #484848;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 5px 10px;
  border-radius: 20px;
  margin-top: 15px;
}

.splash-image {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

/* Modern Button */
.modernButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: $button-color;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: darken($button-color, 10%);
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px $button-color;
  }
}

/* Toggle Button */
.toggleButton {
  margin: 10px;
  padding: 10px 20px;
  background-color: $button-color;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: darken($button-color, 10%);
  }
}

/* Media Queries */
@media (max-width: 767px) {
  .app-container {
    padding: 10px;
  }

  .gamesContainer {
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .gamesContainer {
    justify-content: space-between;
  }
}

#pmLink {
  visibility: hidden;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: none;
}

#pmLink:hover {
  visibility: visible;
  color: grey;
}

.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Highest value to ensure it is above all other elements */
}

/* Ensure dialog box is positioned correctly within the overlay */
.dialog {
  width: 90%;
  max-width: 500px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10000; /* Ensure it's above the overlay */
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dialogContent {
  margin-bottom: 20px;
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;

  &:hover {
    color: #000;
  }
}

.dialogButton {
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: darken($button-color, 10%);
  }
}
