.maintenanceContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
  
    &.darkMode {
      background-color: #2c2c2c;
      color: #fff;
    }
  
    &.lightMode {
      background-color: #f4f4f4;
      color: #333;
    }
  }
  
  .contentWrapper {
    max-width: 600px;
  }
  
  .maintenanceTitle {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .maintenanceText {
    font-size: 1.2rem;
  }
  