
.m-30 {
  margin: 30px;
}

.p-15 {
  padding: 15px;
}

.b-thin-red {
  border: 1px solid red;
}